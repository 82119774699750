import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import { format } from 'date-fns';
import './ReservationSalle.css';
import typeSalle1 from '../../assets/4places.webp'
import typeSalle2 from '../../assets/Salle1_place4.webp'
import typeSalle3 from '../../assets/Salle2_place4.webp'
import typeSalle4 from '../../assets/Salle3_place4.webp'
import typeSalle5 from '../../assets/meeting-room-2170535_1280.webp'
import typeSalle6 from '../../assets/6places.webp'
import typeSalle7 from '../../assets/Salle1_place6.webp'
import typeSalle8 from '../../assets/Salle3_place6.webp'
import typeSalle9 from '../../assets/Salle4_place6.webp'
import typeSalle10 from '../../assets/Salle5_place6.webp'
import detailBookSalle from '../../assets/privateReservation.webp'
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaChevronRight, FaChevronLeft, FaCheck, FaExclamationTriangle, FaCheckCircle, FaUserPlus } from 'react-icons/fa';
import { Slide } from 'react-awesome-reveal';
import { Helmet } from "react-helmet";
import { isAuthenticated } from '../../export/auth';
import axios from 'axios';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../CheckoutForm/CheckoutForm.js";

// import ReservationFormPublic from './ReservationFormPublic';
registerLocale('fr', fr);
const ReservationSalle = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [additionalPersons, setAdditionalPersons] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState(null);

  const [formData, setFormData] = useState({
    date: '',
    departureTime: '',
    duration: '',
    package: '',
    places: [], // Changer le nom de l'état pour "places" et initialiser avec un tableau vide
    // selectedRoomName: ''
  });
  const durationPrices = {
    '1:00': 8,
    '2:00': 16,
    '3:00': 24,
    '4:00': 32,
    '5:00': 40,
    '8:00': 64,
  };

  // Tableau statique des salles en fonction de la capacité
  const roomsByCapacity = {
    'Salon de 4 places': [
      { id: 121, name: 'Salon Espresso', image: typeSalle1 },
      { id: 122, name: 'Salon Arabica', image: typeSalle2 },
      { id: 123, name: 'Salon Mocha', image: typeSalle3 },
      { id: 124, name: 'Salon Cappuccino', image: typeSalle4 },
      { id: 125, name: 'Salon Latte', image: typeSalle5 },
    ],
    'Salon de 6 places': [
      { id: 126, name: 'Salon Macchiato', image: typeSalle6 },
      { id: 127, name: 'Salon Ristretto', image: typeSalle7 },
      { id: 128, name: 'Salon Lungo', image: typeSalle8 },
      { id: 129, name: 'Salon Cortado', image: typeSalle9 },
      { id: 130, name: 'Salon Affogato', image: typeSalle10 },
    ],
  };

  // Stipe 
  const stripePromise = loadStripe("pk_test_51NQqveD7lhAQdyL3v6O9uRrQhRtuqpKPSulewL1P0ogWNGZHLiIhC38zIZ19H9IEnzBbnIYQhjd1zmjLG12lVdvv005lX1bTxD");
  const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: 'flat',
    rules: {
      '.Label': {
        color: '#ffffff',
      },
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {

    // Chargez les salles disponibles lorsque l'étape 5 est affichée
    if (currentStep === 5) {
      setIsLoading(true);
      SallesDisponible();
    }
    // Envoie de l'initiation du paiment à stripe
    if (currentStep === 6) {
      const dataPayment = { price: calculateTotalPrice(formData, selectedRoomType) }
      axios
        .post(
          'https://gustocoffeeapi.fr/api/payment',
          dataPayment,
          {}
        )
        .then((response) => response.data)
        .then((data) => {
          setClientSecret(data.clientSecret)
        });
    }

  }, [currentStep]);

  const [errorMessage, setErrorMessage] = useState('');

  const [availablePlaces, setAvailablePlaces] = useState([]);
  const [allPlaces, setAllPlaces] = useState([]);

  // Fonction pour récupérer les salles disponibles depuis le backend
  const SallesDisponible = () => {

    const data = {
      selectedDate: format(formData.date, 'dd/MM/yyyy'),
      selectedStartTime: formData.departureTime,
      selectedDuration: parseInt(formData.duration.slice(0, -1)),
      type: 'Privé'
    }

    const token = localStorage.getItem('jwtToken'); // Remplacez avec la clé que vous utilisez pour le token JWT

    // Vérifier que le token JWT est disponible
    if (!token) {
      console.error('Token JWT manquant. Assurez-vous que vous êtes connecté.');
      return;
    }
    axios
      .post(
        'https://gustocoffeeapi.fr/api/places_disponibles',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        // Utilisez forEach pour filtrer les places disponibles dont isAvailable est false
        setAllPlaces(data)
        const filteredPlaces = [];
        data.forEach((place) => {

          if (!place.isAvailable) {
            filteredPlaces.push(place);
          }
        });
        setAvailablePlaces(filteredPlaces);
        setIsLoading(false);

      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des salles disponibles :', error);
        setAvailablePlaces([]);
        setIsLoading(false);
      });
  };

  const handleInputChange = (event, index) => {
    const { name, value, type } = event.target;
    if (name === 'room') {
      setSelectedRoomType(value);
      setSelectedRoomId('');
    } else {
      if (name === 'places') {
        let updatedPlaces = [...formData.places];

        if (!updatedPlaces.includes(value)) {
          if (updatedPlaces.length < 1 + additionalPersons.length) {
            updatedPlaces.push(value);
          } else {
            let placeReservation = 1 + additionalPersons.length
            if (placeReservation == 1) {
              setErrorMessage('Votre réservation concerne que ' + placeReservation + ' place');
            } else {
              setErrorMessage('Votre réservation concerne que ' + placeReservation + ' places');
            }

          }

        } else {
          updatedPlaces = updatedPlaces.filter((place) => place !== value);
        }
        setFormData({ ...formData, places: updatedPlaces });
      } else if (name === 'duration') {
        setFormData({ ...formData, [name]: value });
      }
      else if (name === 'package') {
        if (index !== undefined) {
          const updatedPersons = [...additionalPersons];
          updatedPersons[index] = { ...updatedPersons[index], [name]: value };
          setAdditionalPersons(updatedPersons);
        } else {
          setFormData({ ...formData, [name]: value });
        }
      } else if (name === 'name' || name === 'surname') {
        if (index !== undefined) {
          const updatedPersons = [...additionalPersons];
          updatedPersons[index] = { ...updatedPersons[index], [name]: value };
          setAdditionalPersons(updatedPersons);
        } else {
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setFormData({ ...formData, [name]: value });
      }
    }
  };

  const handleRoomSelection = (roomId) => {
    setSelectedRoomId(roomId);
  };

  const nextStep = () => {
    window.scrollTo(0, window.innerHeight * 0.2);

    let error = '';

    // Vérifiez si l'étape actuelle est complète avant de passer à l'étape suivante
    switch (currentStep) {
      case 1:
        if (selectedRoomType === '') {
          error = "Veuillez choisir un type de salle";
        }
        break;
      case 2:
        if (formData.date === '') {
          error = "Veuillez sélectionner une date.";
        }
        break;
      case 3:
        if (formData.departureTime === '') {
          error = "Veuillez sélectionner une heure de début.";
        }
        break;
      case 4:
        if (formData.duration === '') {
          error = "Veuillez sélectionner une durée.";
        }
        break;
      case 5:
        if (selectedRoomId === '') {
          error = "Veuillez sélectionner une salle";
        }
        break;
      case 6:
        if (formData.place === '') {
          error = "Veuillez sélectionner une place.";
        }
        break;
      default:
        break;
    }

    if (error !== '') {
      setErrorMessage(error);
      window.scrollTo(0, window.innerHeight * 0.7);
    } else {
      setErrorMessage('');
      setCompletedSteps([...completedSteps, currentStep]); // Ajoute l'étape actuelle aux étapes complétées
      setCurrentStep(currentStep + 1);
    }
  };

  const goToStep = (step) => {
    if (completedSteps.includes(step)) {
      setCurrentStep(step);
    }
  };

  // Fonction pour revenir à l'étape précédente
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  //Alerte
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour gérer le chargement
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const hideAlert = () => {
    setIsAlertVisible(false);
  };

  const calculateTotalPrice = (formData, selectedRoomType) => {
    const durationPrice = durationPrices[formData.duration] || 0; // Default to 0 if duration is not found

    let additionalCost = 0;
    let nb_place = 0;
    // Calcul du prix du forfait
    if (formData.package === 'Découverte') {
      additionalCost += 9;
    } else if (formData.package === 'Barista') {
      additionalCost += 18;
    }

    if (selectedRoomType === 'Salon de 4 places') {
      nb_place = 4
    } else if (selectedRoomType === 'Salon de 6 places') {
      nb_place = 6
    }

    additionalCost = additionalCost * nb_place


    const totalPrice = (durationPrice * nb_place) + additionalCost;
    return totalPrice;
  };



  // Fonction pour soumettre le formulaire
  const handleReservationSuccess = () => {

    setIsLoading(true);
    const data = {
      reservation_date: format(formData.date, 'dd/MM/yyyy'),
      reservation_start_time: formData.departureTime,
      reservation_duration: formData.duration,
      price: calculateTotalPrice(formData, selectedRoomType),
      reservation_details: [
        { roomtype_id: roomsByCapacity[selectedRoomType].find(room => room.id === selectedRoomId).name, packages_id: formData.package }
      ]
    };

    const json = JSON.stringify(data);

    const token = localStorage.getItem('jwtToken'); // Remplacez avec la clé que vous utilisez pour le token JWT

    // Vérifier que le token JWT est disponible
    if (!token) {
      console.error('Token JWT manquant. Assurez-vous que vous êtes connecté.');
      return;
    }

    axios.post('https://gustocoffeeapi.fr/api/reservations', json, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        //console.log("inscription".response);
        //console.log(response.data);
        if (response.status === 201) {
          setIsLoading(false); // Cacher l'icône de chargement
          setAlertMessage('Réservation réussi ! Vous allez recevoir une confirmation par e-mail.');
          setIsAlertVisible(true); // Afficher l'alerte de succès
          setTimeout(() => {
            setIsAlertVisible(false); // Masquer l'alerte après 5 secondes (2000 millisecondes)
            window.location.href = "https://www.dsp-devo21-ob-kk-et-abm.fr/Profile";
          }, 2000);
        } else {
          setIsLoading(false); // Cacher l'icône de chargement
          setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
          setIsAlertVisible(true); // Afficher l'alerte d'erreur
          setTimeout(() => {
            setIsAlertVisible(false); // Masquer l'alerte après 5 secondes (2000 millisecondes)
            window.location.href = "https://www.dsp-devo21-ob-kk-et-abm.fr";
          }, 2000);
        }
      })
      .catch((error) => {
        //console.log("ERROR", error);
        setIsLoading(false); // Cacher l'icône de chargement
        setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
        setIsAlertVisible(true); // Afficher l'alerte d'erreur
        setTimeout(() => {
          setIsAlertVisible(false); // Masquer l'alerte après 5 secondes (2000 millisecondes)
          window.location.href = "https://www.dsp-devo21-ob-kk-et-abm.fr";
        }, 2000);
      });

  };

  const isDateSelectable = (date) => {
    // Vérifiez si la date est passée
    const currentDate = new Date();
    return date >= currentDate;
  };

  const isDateDisabled = (date) => {
    const currentDate = new Date();
    const isPastDate = date < currentDate;

    // Ajouter d'autres dates à désactiver si nécessaire
    const additionalDisabledDates = [
      new Date(2023, 5, 25), // Exemple de date à désactiver
      new Date(2023, 5, 30), // Exemple de date à désactiver
    ];

    // Vérifier si la date est dans la liste des dates désactivées
    const isDisabled = additionalDisabledDates.some((disabledDate) => date.toDateString() === disabledDate.toDateString());

    return isPastDate || isDisabled;
  };

  const renderProgressBar = () => {
    const steps = [
      { number: 1, label: 'Capacité' },
      { number: 2, label: 'Date' },
      { number: 3, label: 'Début' },
      { number: 4, label: 'Durée' },
      { number: 5, label: 'Salle' },
      { number: 6, label: 'Résumé' },
      { number: 7, label: 'Paiement' },
    ];

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Réservez votre esapace de travail en quelques clics - Gusto Coffee</title>
          <meta name="description" content="Facilitez-vous la vie en réservant votre salle de coworking dans notre café en ligne. Profitez d'une réservation simple et rapide, et garantissez-vous un espace confortable pour travailler ou savourer votre café préféré avec votre équipe. Réservez dès maintenant !" />
          <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/ReservationSalle" />
        </Helmet>
        <div className="customProgress-bar">
          {steps.map((step, index) => (
            <React.Fragment key={step.number}>
              <div
                className={`progress-step ${currentStep === step.number ? 'active' : ''} ${completedSteps.includes(step.number) ? 'completed' : ''}`}
                onClick={() => goToStep(step.number)}
              >
                <div className="step-label">{step.label}</div>
              </div>
              {index !== steps.length - 1 && <div className="progress-bar-line"></div>}
            </React.Fragment>
          ))}
        </div>
        <div className="container-m-bar container">
          {steps.map((step, index) => (
            <React.Fragment key={step.number}>
              <div className="row-m-bar">
                <div className="bloc-bar">
                  <p className="label-m">{step.label}</p>
                  <div
                    className={`circle ${currentStep === step.number ? 'active' : ''} ${completedSteps.includes(step.number) ? 'completed' : ''}`}
                    onClick={() => goToStep(step.number)}
                  >
                    {completedSteps.includes(step.number) ? <FaCheck /> : step.number}
                  </div>
                </div>
              </div>
              {index !== steps.length - 1 && <div className={`line ${completedSteps.includes(step.number) ? 'completed' : ''}`}></div>}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  // Rendu conditionnel en fonction de l'étape actuelle
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className='choix-salle'>
            <h2 className='text-center text-white h2-bis mb-5'>Étape 1 : Choisissez votre type de salle</h2>
            <div className='roomForm row'>

              <div className='room col-12 col-lg-6 mb-lg-0 mb-5'>
                <h4>Réservez une salle de 4 places</h4>
                <img src={typeSalle1} alt="Réservation public" className={`img-fluid w-100 ${selectedRoomType === 'Salon de 4 places' ? 'selected-image' : ''}`} />
                <div className='mt-4 d-flex justify-content-center'>
                  <input type="radio" className={`btn-check ${selectedRoomType === 'Salon de 4 places' ? 'checked' : ''}`} name="room" value="Salon de 4 places" onChange={handleInputChange} id="danger-outlined" autocomplete="off" checked={selectedRoomType === 'Salon de 4 places'} />
                  <label className="btn btn-outline" for="danger-outlined">Sélectionner 4 places</label>
                </div>
              </div>
              <div className='room col-12 col-lg-6'>
                <h4 >Réservez une salle de 6 places</h4>
                <img src={typeSalle6} alt="Réservation salle complete" className={`img-fluid w-100 ${selectedRoomType === 'Salon de 6 places' ? 'selected-image' : ''}`} />
                <div className='mt-4 d-flex justify-content-center'>
                  <input type="radio" className={`btn-check ${selectedRoomType === 'Salon de 6 places' ? 'checked' : ''}`} name="room" value="Salon de 6 places" onChange={handleInputChange} id="success-outlined" autocomplete="off" checked={selectedRoomType === 'Salon de 6 places'} />
                  <label class="btn btn-outline" for="success-outlined">Sélectionner 6 places</label>
                </div>
              </div>
            </div>
            <Slide direction="up" duration={200} className='d-flex justify-content-center'>
              {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
            </Slide>

            <div className='d-flex justify-content-end mt-5'>
              <button className='customBtn next second-btn' onClick={nextStep}>Suivant<span className='chevron-right'><FaArrowRight /></span></button>
            </div>
          </div>

        );

      case 2:
        return (
          <div>
            {/* Formulaire pour l'étape 1 */}
            <div className=''>
              <h2 className='text-center text-white h2-bis'>Étape 2 : Choisissez la date de la réservation </h2>
              <div className='d-flex justify-content-center'>
                <DatePicker
                  locale="fr"
                  selected={formData.date}
                  onChange={(date) => handleInputChange({ target: { name: 'date', value: date } })}
                  dateFormat="dd/MM/yyyy"
                  inline
                  minDate={new Date()} // Désactive les dates passées
                  filterDate={isDateSelectable} // Sélectionne uniquement les dates non passées

                  dayClassName={(date) => {
                    const classNames = [];
                    if (!isDateSelectable(date)) {
                      classNames.push('disabled-date');
                    }

                    return classNames.join(' ');
                  }}
                  dayPickerProps={{
                    disabledDays: isDateDisabled,
                  }}
                />
              </div>
              <Slide direction="up" duration={200} className='d-flex justify-content-center'>
                {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
              </Slide>
              <div className='col-12 col-md-12 d-flex bottomContainer'>
                <div className='d-flex'>
                  <button className='customBtn add' onClick={prevStep}>
                    <span className='chevron-left'><FaArrowLeft /></span>Retour</button>
                </div>
                <div className='ml-auto'>
                  <button className='customBtn next second-btn' onClick={nextStep}>Suivant<span className='chevron-right'><FaArrowRight /></span></button>
                </div>
              </div>
            </div>

          </div>
        );
      case 3:
        const hours = ['8:00', '9:00', '10:00', '13:00', '13:30', '14:00'];
        return (
          <div className='container heure'>
            <h2 className='text-white text-center h2-bis'>Étape 3 : À quelle heure doit commencer votre réservation ?</h2>
            <div className='row justify-content-center contain-card'>
              <div className='col-12 col-lg-8 text-center'>
                <div className='row row-cols-3 row-cols-lg-6 justify-content-between cardContainer'>
                  {hours.map((hour) => (
                    <div className='col d-flex justify-content-center' key={hour}>
                      <button
                        className={`custom-card ${formData.departureTime === hour ? 'selected' : ''}`}
                        onClick={() => handleInputChange({ target: { name: 'departureTime', value: hour } })}
                      >
                        <div className='my-auto mx-auto'>{hour}</div>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <Slide direction="up" duration={200} className='d-flex justify-content-center'>
                {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
              </Slide>
              <div className='col-12 col-md-12 d-flex bottomContainer'>
                <div className='d-flex'>
                  <button className='customBtn add' onClick={prevStep}>
                    <span className='chevron-left'><FaArrowLeft /></span>Retour
                  </button>
                </div>
                <div className='ml-auto'>
                  <button className='customBtn next second-btn' onClick={nextStep}>
                    Suivant<span className='chevron-right'><FaArrowRight /></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        );

      case 4:
        const duration = ['1:00', '2:00', '3:00', '4:00', '5:00', '8:00'];
        return (
          <div className='container heure'>
            <h2 className='text-white text-center h2-bis'>Étape 4 : Combien de temps dure votre réservation ?</h2>
            <div className='row justify-content-center contain-card'>
              <div className='col-12 col-lg-8 text-center'>
                <div className='row row-cols-3 row-cols-lg-6 justify-content-between cardContainer'>
                  {duration.map((hour) => (
                    <div className='col d-flex justify-content-center' key={hour}>
                      <button
                        className={`custom-card ${formData.duration === hour ? 'selected' : ''}`}
                        onClick={() => handleInputChange({ target: { name: 'duration', value: hour } })}
                      >
                        <div className='my-auto mx-auto'>{hour}</div>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Slide direction="up" duration={200} className='d-flex justify-content-center'>
              {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
            </Slide>
            <div className='col-12 col-md-12 d-flex bottomContainer'>
              <div className='d-flex'>
                <button className='customBtn add' onClick={prevStep}>
                  <span className='chevron-left'><FaArrowLeft /></span>Retour
                </button>
              </div>
              <div className='ml-auto'>
                <button className='customBtn next second-btn' onClick={nextStep}>
                  Suivant<span className='chevron-right'><FaArrowRight /></span>
                </button>
              </div>
            </div>
          </div>
        );

      case 5:
        return (
          <div>
            {isLoading && (
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {selectedRoomType && (
              <div>
                <h2 className='text-white text-center h2-bis mb-5'>Étape 5 : Choisissez votre salle et forfait</h2>
                <div className='roomForm row justify-content-center'>
                  {roomsByCapacity[selectedRoomType].map(room => {
                    const placeIndex = room.id - 121; // Index dans allPlaces
                    if (placeIndex < allPlaces.length) {
                      const salle = allPlaces[placeIndex];
                      const isPlaceAvailable = !salle.isAvailable;
                      return (
                        <div className='col-12 col-md-12 col-lg-6 mb-4 d-flex justify-content-center' key={room.id}>
                          <div
                            className={`${isPlaceAvailable ? 'room-card' : ''}`}
                            onClick={() => handleRoomSelection(room.id)}
                          >
                            <img src={room.image} alt={room.name} className={`img-fluid pointer max-img ${selectedRoomId === room.id ? 'selected-image' : ''}`} />
                            <h4 className={`mt-3 ${selectedRoomId === room.id ? 'selected-title' : ''}`}>{room.name}<span className='orange'> {`${isPlaceAvailable ? '(Indisponible)' : ''}`}</span></h4>
                          </div>
                        </div>
                  )} else {
                    return null; // Pour les emplacements vides à la fin
                  }})}
                </div>
                <label className="form-label text-white" forHtml="package">Forfait<span className="orange"></span></label>
                <select className='form-select mb-5'
                  name="package"
                  id='package'
                  value={formData.package}
                  onChange={(event) => handleInputChange(event)}
                >
                  <option value="">Sélectionnez un forfait</option>
                  <option value="Aucun">Aucun</option>
                  <option value="Découverte">Forfait Découverte (9€)</option>
                  <option value="Barista">Forfait Barista (18€)</option>
                </select>
              </div>
            )}
            <Slide direction="up" duration={200} className='d-flex justify-content-center'>
              {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
            </Slide>
            <div className='col-12 col-md-12 d-flex bottomContainer'>
              <div className='d-flex'>
                <button className='customBtn add' onClick={prevStep}>
                  <span className='chevron-left'><FaArrowLeft /></span>Retour
                </button>
              </div>
              <div className='ml-auto'>
                <button className='customBtn next second-btn' onClick={nextStep}>
                  Suivant<span className='chevron-right'><FaArrowRight /></span>
                </button>
              </div>
            </div>
          </div>
        );

      case 6:
        const formattedDate = format(formData.date, 'dd/MM/yyyy');
        const durationPrice = durationPrices[formData.duration];
        return (
          <div>
            <h2 className='text-white text-center h2-bis'> Récapitulatif de la réservation</h2>
            <div className='divider mt-4'></div>
            <div className='container-resume'>
              <div className="card">
                <img src={detailBookSalle} alt="Détails réservation" className='card-img-top' />
                <div className="card-body text-center">
                  <h5 className="card-title">Détails de votre réservation</h5>
                  <p className='card-text'><u>Date de réservation</u> : <b>{format(formData.date, 'dd/MM/yyyy')}</b></p>
                  <p className='card-text'><u>Heure de début</u> : <b>{formData.departureTime}</b></p>
                  <p className='card-text'><u>Durée</u> : <b>{formData.duration}</b></p>
                  <p className='card-text'><u>Prix</u> : <b>{calculateTotalPrice(formData, selectedRoomType)} €</b></p>
                  <p className='card-text'><u>Type de salle choisie</u> : <b>{selectedRoomType}</b></p>
                  <p className='card-text'><u>Salle choisie</u> : <b>{roomsByCapacity[selectedRoomType].find(room => room.id === selectedRoomId).name}</b></p>
                  <p className='card-text'><u>Forfait choisie</u> : <b>{formData.package}</b></p>
                  <a className="btn customBtn submit-book" onClick={nextStep} style={{ marginLeft: 'auto' }}>Valider <span className='chevron-right'><FaCheck /></span></a>
                </div>
              </div>
              <div className='col-12 col-md-12 d-flex bottomContainer'>
                <div className='d-flex'>
                  <button className='customBtn add' onClick={prevStep}>
                    <span className='chevron-left'><FaArrowLeft /></span>Retour</button>
                </div>
              </div>
            </div>
            {isAlertVisible && (
              <div
                className={`alert-box alert d-flex align-items-center ${alertMessage.includes('réussi') ? 'alert-success' : 'alert-danger'
                  }`}
                role="alert"
              >
                {alertMessage.includes('réussi') ? (
                  <FaCheckCircle className="bi flex-shrink-0 me-2" aria-label="Success" />
                ) : (
                  <FaExclamationTriangle className="bi flex-shrink-0 me-2" aria-label="Danger" />
                )}
                <div>{alertMessage}</div>
              </div>
            )}
          </div>
        );
      case 7:

        return (
          <div className=''>
            <h2 className='text-white text-center h2-bis'> Paiement de la réservation</h2>
            <div className='divider'></div>
            <div className='container-resume'>
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm onPaymentSuccess={handleReservationSuccess} />
                </Elements>
              )}
              <div className="col-5">
                <p className='text-white'>
                  <span className="orange">* </span>Utiliser le numéro de carte suivant pour faire le test de paiement :
                  <code className='orange'> 4242 4242 4242 4242</code> (Visa)
                </p>
                <p className='text-white'> Plus d'infos sur la <a href="https://stripe.com/docs/testing#cards" target="_blank" rel="noopener noreferrer"> documentation Stripe </a> </p>
              </div>
            </div>

            <div className='col-12 col-md-12 d-flex bottomContainer'>
              <div className='d-flex'>
                <button className='customBtn add' onClick={prevStep}>
                  <span className='chevron-left'><FaChevronLeft /></span>Retour</button>
              </div>
            </div>
            {isLoading && (
              <div className="alert-box-spinner">
                <span className="loader"></span>
              </div>
            )}
            {isAlertVisible && (
              <div
                className={`alert-box alert d-flex align-items-center ${alertMessage.includes('réussi') ? 'alert-success' : 'alert-danger'
                  }`}
                role="alert">
                {alertMessage.includes('réussi') ? (
                  <FaCheckCircle className="bi flex-shrink-0 me-2" aria-label="Success" />
                ) : (
                  <FaExclamationTriangle className="bi flex-shrink-0 me-2" aria-label="Danger" />
                )}
                <div>{alertMessage}</div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='reservation-f container'>
      <h1 className='text-center'>Réservation d'une salle privatisée</h1>
      <div className="divider-gold mt-4 mb-lg-5 mb-4"></div>
      <Link className='iconBack' to="/TypeDeReservation">
        <span className='ml-arrow-back'><FaArrowLeft /></span>
        <span className='back-hover' style={{ marginLeft: '5px' }}>Réserver une place ?</span>
      </Link>
      <div className='bar-d'>{renderProgressBar()}</div>
      <section id='reservation-salle-section'>
        <div className="row justify-content-center">
          <div id='reservation-formulaire' className="col-lg-10">
            <div className="row">
              <div className='bar-mobile'>{renderProgressBar()}</div>
              {renderStep()}
            </div>
          </div>
        </div>
      </section>
    </div>
  );

};

export default ReservationSalle;
