import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import './CheckoutForm.css';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm(props) {
  // Utilisation des hooks de React pour gérer l'état et les effets
  const stripe = useStripe(); // Accès à l'objet Stripe
  const elements = useElements(); // Accès aux éléments de paiement


  // État local pour gérer le message, et l'état de chargement
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Effet qui s'exécute lorsque 'stripe' est modifié
  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Récupération du 'clientSecret' depuis les paramètres de l'URL
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    // Récupération et traitement du statut du paiement depuis Stripe
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Paiement réussi !");
          break;
        case "processing":
          setMessage("Votre paiement est en cours de traitement.");
          break;
        case "requires_payment_method":
          setMessage("Votre paiement n'a pas abouti, veuillez réessayer.");
          break;
        default:
          setMessage("Quelque chose s'est mal passé.");
          break;
      }
    });
  }, [stripe]);

  // Gestion de la soumission du formulaire de paiement
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js n'a pas encore été chargé.
      // Désactive la soumission du formulaire jusqu'à ce que Stripe.js soit chargé.
      return;
    }

    setIsLoading(true);

    // Confirmation du paiement à l'aide de Stripe
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://www.dsp-devo21-ob-kk-et-abm.fr/",
      },
      redirect: 'if_required' ,
    });

    // Gérer l'erreur
    if (error) {
      //console.error(error);
      // handleError();
    } else if (error == null) {
      //console.log("Payment succeeded");
      props.onPaymentSuccess(); // Appeler la fonction de réservation
    } else {
      //console.log("Payment failed");
      // handleOther();
    }
    setIsLoading(false);

  };

  // Options pour la configuration de l'élément de paiement
  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form className="container formPayment" id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button className="PaymentButton" disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Payer"}
        </span>
      </button>
      {/* Afficher les messages d'erreur ou de succès  */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}