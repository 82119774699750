import React, { useState } from "react";
import './Identifier.css';
import jwt_decode from "jwt-decode"
// import { useHistory } from "react-router-dom";
import axios from 'axios';
import api from '../../export/api';
import { FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa';
import { Helmet } from "react-helmet";
import Swal from 'sweetalert2';


const Identifier = () => {
  // États pour gérer les formulaires de connexion et d'inscription, ainsi que les messages d'alerte et de chargement
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: ""
  });


  const [registerForm, setRegisterForm] = useState({
    lastname: "",
    firstname: "",
    email: "",
    password: ""
  });
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour gérer le chargement
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const hideAlert = () => {
    setIsAlertVisible(false);
  };

  // Fonction pour gérer les changements dans le formulaire de connexion
  const handleLoginChange = (e) => {
    setLoginForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));
  };

  // Fonction pour gérer les changements dans le formulaire d'inscription
  const handleRegisterChange = (e) => {
    setRegisterForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }));
  };

  // Fonction pour gérer la soumission du formulaire de connexion
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      // Réponse de la requête de connexion
      const response = await api.post('https://gustocoffeeapi.fr/api/login', loginForm);
      const { token } = response.data;
      localStorage.setItem('jwtToken', token);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Décoder le token JWT pour obtenir les informations de l'utilisateur
      const decodedToken = jwt_decode(token);
      const userPrenom = decodedToken.username;
      const { roles } = decodedToken;
  
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success btn-alert-d',
          cancelButton: 'btn btn-danger btn-alert-d'
        },
        buttonsStyling: false
      });
  
      try {
        const profileResponse = await api.get('https://gustocoffeeapi.fr/api/profile', { headers: { Authorization: `Bearer ${token}` } });
        const { actif } = profileResponse.data;
  
        if (actif === false) {
          swalWithBootstrapButtons.fire({
            title: 'Réactivation du compte',
            text: "Votre compte est inactif. Voulez-vous le réactiver ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, réactiver !',
            cancelButtonText: 'Non, annuler !',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              api.put('https://gustocoffeeapi.fr/api/profile', { actif: true }, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                  swalWithBootstrapButtons.fire(
                    'Réactivé!',
                    'Votre compte a été réactivé.',
                    'success'
                  ).then(() => {
                    redirectUser(roles);
                  });
                })
                .catch(error => {
                  swalWithBootstrapButtons.fire(
                    'Erreur!',
                    'Il y a eu une erreur lors de la réactivation de votre compte.',
                    'error'
                  );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire(
                'Annulé',
                'Votre compte reste inactif.',
                'error'
              ).then(() => {
                setIsLoading(false);
              });
            }
          });
        } else {
          redirectUser(roles);
        }
      } catch (profileError) {
        console.error('Erreur en récupérant les détails du profil:', profileError);
      }
    } catch (error) {
      console.log("ERROR", error);
      setIsLoading(false);
      setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.');
      setIsAlertVisible(true);
      setTimeout(hideAlert, 5000);
    }
  
    function redirectUser(roles) {
      if (Array.isArray(roles)) {
        if (roles.includes('ROLE_ADMIN')) {
          window.location.href = 'https://gustocoffeeapi.fr/admin';
        } else {
          window.location.href = '/Profile';
        }
      }
      setIsLoading(false);
    }
  };
  

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    //console.log("Register form submitted:", registerForm);

    axios.post('https://gustocoffeeapi.fr/api/inscription', registerForm)
      .then((response) => {
        //console.log("inscription".response);
        //console.log(response.data);
        if (response.data["message"] === "Inscription réussie") {
          setIsLoading(false); // Cacher l'icône de chargement
          setAlertMessage('Inscription réussi ! Vous allez recevoir une confirmation par e-mail.');
          setRegisterForm({ // Supposons que setRegisterForm est une fonction pour mettre à jour l'état du formulaire
            lastname: "",
            firstname: "",
            email: "",
            password: ""
          });
          axios.post('https://gustocoffeeapi.fr/send-email/inscription', registerForm)
      .then((response) => {}).catch((error) => {
        //console.log("ERROR", error);
        setIsLoading(false); // Cacher l'icône de chargement
        setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
        setIsAlertVisible(true); // Afficher l'alerte d'erreur
        setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
      });
          setIsAlertVisible(true); // Afficher l'alerte de succès
          setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
        } else {
          setIsLoading(false); // Cacher l'icône de chargement
          setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
          setIsAlertVisible(true); // Afficher l'alerte d'erreur
          setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
        }
      })
      .catch((error) => {
       // Gestion des erreurs en cas d'échec d'inscription
        setIsLoading(false); // Cacher l'icône de chargement
        setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
        setIsAlertVisible(true); // Afficher l'alerte d'erreur
        setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
      });

  };
  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  }

  return (
    <div className="container-fluid Identifier">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Connexion/Inscription - Gusto Coffee</title>
        <meta name="description" content="Connectez-vous ou inscrivez-vous sur notre site pour accéder à nos services de coworking."/>
        <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/Identifier" />
      </Helmet>
      <div className="row">
        <div className="col cnx col-12 col-lg-6">
          <h2 className="text-2xl font-bold text-gray-800 h2-center">J’ai déjà un compte</h2>
          <div className="divider mt-4"></div>
          <form className="mt-6 form_top identifier-form" onSubmit={handleLoginSubmit}>
            <div>
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={loginForm.email}
                onChange={handleLoginChange}
                required
              />
            </div>
            <div className="mt-4">
              <label className="form-label" htmlFor="password">
                Mot de passe
              </label>
              <input
                className="form-control"
                type="password"
                id="password"
                name="password"
                placeholder="Mot de passe"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
                // value={loginForm.password}
                onChange={handleLoginChange}
                required
              />
            </div>
            <div className="mt-4">
              <p className="forgot-password ">

                <a href="/MdpOublier" onClick={handleForgotPasswordClick} className="lien">
                  Mot de passe oublié ?
                </a>
              </p>
            </div>
            <div class="col-md-12 d-flex justify-content-end">
              <input type="submit" value="Se connecter" class="customBtn b ml-auto" />
            </div>
          </form>
          {isLoading && (
        <div className="alert-box-spinner">
          <span className="loader"></span>
        </div>
      )}
      {isAlertVisible && (
        <div
          className={`alert-box alert d-flex align-items-center ${alertMessage.includes('réussi') ? 'alert-success' : 'alert-danger'
            }`}
          role="alert"
        >
          {/* Utilisez un ternaire pour afficher l'icône en fonction de la classe */}
          {alertMessage.includes('réussi') ? (
            <FaCheckCircle className="bi flex-shrink-0 me-2" aria-label="Success" />
          ) : (
            <FaExclamationTriangle className="bi flex-shrink-0 me-2" aria-label="Danger" />
          )}
          <div>{alertMessage}</div>
        </div>
      )}
        </div>
        <div className="inscription col-12 col-lg-6">
          <h2 className="text-2xl font-bold text-white h2-center">Pas de Compte ? Inscrivez-vous ! </h2>
          <div className="divider mt-4"></div>
          <form className="mt-6 form_top identifier-form" onSubmit={handleRegisterSubmit}>
            <div>
              <label className="form-label" htmlFor="lastname">
                Nom
              </label>
              <input
                className="form-control"
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Nom"
                value={registerForm.lastname}
                onChange={handleRegisterChange}
                required
              />
            </div>
            <div className="mt-4">
              <label className="form-label" htmlFor="firstname">
                Prénom
              </label>
              <input
                className="form-control"
                type="text"
                id="firstname"
                name="firstname"
                placeholder="Prénom"
                value={registerForm.firstname}
                onChange={handleRegisterChange}
                required
              />
            </div>
            <div className="mt-4">
              <label className="form-label" htmlFor="email-sub">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                id="email-sub"
                name="email"
                placeholder="Email"
                value={registerForm.email}
                onChange={handleRegisterChange}
                required
              />
            </div>
            <div className="mt-4">
              <label className="form-label" htmlFor="password-sub">
                Mot de passe
              </label>
              <input
                className="form-control"
                type="password"
                id="password-sub"
                name="password"
                placeholder="Mot de passe"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
                // value={registerForm.password}
                onChange={handleRegisterChange}
                required
              />
            </div>
            <div className="mt-4">
              <p>
                <span className="orange">* </span>Au moins 8 caractères, dont 1 majuscule, 1 minuscule et 1 chiffre.
              </p>
            </div>
            <div className="form-check">

              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
              <label class="form-check-label" for="flexCheckDefault">

                En créant votre compte vous acceptez les Conditions générales d'utilisation, les Conditions générales de vente et la politique de confidentialité de Gusto Coffee et confirmez avoir plus de 16 ans.
              </label>
            </div>
            <div class="col-md-12 d-flex justify-content-end mt-4">
              <input type="submit" value="S'inscrire" class="customBtn b ml-auto" />
            </div>
            {isLoading && (
              <div className="alert-box-spinner">
                <span className="loader"></span>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};


Identifier.propTypes = {};

Identifier.defaultProps = {};


export default Identifier;

