import React, { useState } from 'react';
import './CustomDrink.css';
import { Configuration, OpenAIApi } from 'openai';

// Cette fonction représente un composant React qui permet de créer des boissons en choisissant des ingrédients et un type de boisson.
function IngredientForm() {

  // Déclaration des états utilisés dans le composant.
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [drinkType, setDrinkType] = useState('chaude');
  const [generatedDrink, setGeneratedDrink] = useState('');

  // Fonction appelée lorsqu'un ingrédient est sélectionné ou désélectionné.
  const handleIngredientChange = (ingredient) => {
    if (selectedIngredients.includes(ingredient)) {
      setSelectedIngredients(selectedIngredients.filter(item => item !== ingredient));
    } else {
      setSelectedIngredients([...selectedIngredients, ingredient]);
    }
  };

  // Fonction appelée lorsqu'un nouveau type de boisson est sélectionné.
  const handleDrinkTypeChange = (type) => {
    setDrinkType(type);
  };

  // Fonction pour générer la boisson en utilisant l'API GPT-3.
  const generateDrink = async () => {
    const ingredientsText = selectedIngredients.join(', ');
    const capitalizedDrinkType = drinkType[0].toUpperCase() + drinkType.slice(1).toLowerCase();

    try {
      const configuration = new Configuration({
        apiKey: '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',
      });

      const openaiClient = new OpenAIApi(configuration);

      const prompt = generatePrompt(capitalizedDrinkType, ingredientsText);
      const response = await openaiClient.createCompletion({
        model: 'text-davinci-003',
        prompt: prompt,
        max_tokens: 100,
      });

      const generatedText = response.data.choices[0].text;
      setGeneratedDrink(generatedText);
    } catch (error) {
      console.error('Erreur lors de l\'appel à l\'API GPT-3 :', error);
    }
  };
  
    // Fonction pour générer le texte de la requête à partir du type de boisson et des ingrédients.
  const generatePrompt = (drinkType, ingredientsText) => {
    return `Suggère une boisson de type ${drinkType} avec comme ingrédients principaux les ingrédients suivants : ${ingredientsText}. A la ligne, un très court descriptif de la boisson.`;
  };

    const ingredients = ['Café', 'Lait', 'Chocolat', 'Caramel', 'Crème', 'Sucre', 'Chantilly', 'Vanille', 'Cannelle', 'Noix de coco râpée', 'Menthe fraîche', 'Baies (framboises, myrtilles, fraises)', 'Amandes effilées', 'Noix de pécan', "Sirop d'érable", "Sirop de caramel salé", "Gingembre frais", "Miel", "Crème fouettée au chocolat", "Chocolat blanc râpé", "Citron vert ou zeste d'orange", "Chai tea", "Morceaux de cookies", "Morceaux de brownies", "Lait d'amende", "Lait de coco", "Lait d'avoine"];
    
    return (
        <div className="container mt-5 mb-5" id='custom-drink'>
            <div className='text-center'>
                <h2>Votre Boisson Sur Mesure !</h2>
            </div>
            <div className='divider'></div>
        <div className="row">
            <div className="col-md-4 border-r pb-4 mb-4 mb-md-0">
            <h3><span className='orange'>1.</span> Sélection</h3>
            <h4>Choix du type de boisson :</h4>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                value="chaude"
                checked={drinkType === 'chaude'}
                onChange={() => handleDrinkTypeChange('chaude')}
                id="drink-hot"
                />
                <label className="form-check-label" htmlFor="drink-hot">
                Chaude
                </label>
            </div>
            <div className="form-check">
                <input
                className="form-check-input"
                type="radio"
                value="froide"
                checked={drinkType === 'froide'}
                onChange={() => handleDrinkTypeChange('froide')}
                id="drink-cold"
                />
                <label className="form-check-label" htmlFor="drink-cold">
                Froide
                </label>
            </div>

            <h4>Choix des ingrédients :</h4>
            <div className="row">
                <div className="col-md-6">
                    {ingredients.slice(0, 15).map((ingredient, index) => (
                        <div className="form-check" key={index}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={ingredient}
                                checked={selectedIngredients.includes(ingredient)}
                                onChange={() => handleIngredientChange(ingredient)}
                                id={`ingredient-${index}`}
                            />
                            <label className="form-check-label" htmlFor={`ingredient-${index}`}>
                                {ingredient}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="col-md-6">
                    {ingredients.slice(15, 30).map((ingredient, index) => (
                        <div className="form-check" key={index + 10}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={ingredient}
                                checked={selectedIngredients.includes(ingredient)}
                                onChange={() => handleIngredientChange(ingredient)}
                                id={`ingredient-${index + 10}`}
                            />
                            <label className="form-check-label" htmlFor={`ingredient-${index + 10}`}>
                                {ingredient}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
            </div>

            <div className="col-md-4 border-r mb-4 mb-md-0">
            <h3><span className='orange'>2.</span> Récap</h3>
            <h4>Type de boisson sélectionné :</h4>
            <p>{drinkType === 'chaude' ? 'Chaude' : 'Froide'}</p>

            <h4>Ingrédients sélectionnés :</h4>
            <ul>
                {selectedIngredients.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
                ))}
            </ul>
            </div>

            <div className="col-md-4">
            <h3><span className='orange'>3.</span> Proposition de boisson</h3>
            <h4>Boisson générée par l'IA :</h4>
            <div className='mt-4'>
                {generatedDrink && <p className='ia-resp'>{generatedDrink}</p>}
            </div>
            <button className="btn customBtn mt-5" onClick={generateDrink}>
                Générer Boisson
            </button>
            </div>
        </div>
        </div>
    );
}

export default IngredientForm;

