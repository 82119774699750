import React from 'react';
import PropTypes from 'prop-types';
import './PolitiqueConfidentialite.css';
import { Helmet } from "react-helmet";

const PolitiqueConfidentialite = () => (
  <div className="PolitiqueConfidentialite">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Politique de confidentialité - Gusto Coffee</title>
      <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/politique-confidentialite" />
    </Helmet>
    <div className='container'>
      <h2 className='text-center'>Politique de Confidentialité</h2>
      <div className='divider'></div>
      <p>Dernière mise à jour : 19/07/2023</p>

      <p>La présente politique de confidentialité décrit comment la société Gusto Coffee collecte, utilise, protège et divulgue les informations personnelles des utilisateurs de son site web. En utilisant notre site web, vous acceptez les termes de cette politique de confidentialité.</p>

      <h3>Collecte et Utilisation des Informations</h3>

      <p>Nous pouvons collecter des informations personnelles vous concernant lorsque vous utilisez notre site web, notamment lorsque vous effectuez une réservation d'espace de travail, que vous souscrivez à l'un de nos forfaits ou que vous nous contactez via le formulaire de contact.</p>

      <p>Les informations que nous pouvons collecter comprennent, sans s'y limiter, votre nom, votre adresse e-mail, votre numéro de téléphone et d'autres informations personnelles que vous choisissez de fournir.</p>

      <p>Nous utilisons les informations personnelles collectées pour les finalités suivantes :</p>

      <ul>
        <li>Gérer les réservations d'espaces de travail, que ce soit en salon privé ou en place individuelle.</li>
        <li>Vous fournir les services associés aux forfaits que vous avez souscrits, tels que l'accès à nos cafés du jour et aux viennoiseries.</li>
        <li>Vous contacter pour répondre à vos questions ou demandes de renseignements.</li>
      </ul>

      <h3>Protection des Informations</h3>

      <p>Nous prenons des mesures de sécurité raisonnables pour protéger les informations personnelles que nous collectons et empêcher tout accès non autorisé ou divulgation de ces informations.</p>

      <h3>Divulgation des Informations</h3>

      <p>Nous ne vendons, ne louons ni ne partageons vos informations personnelles avec des tiers, sauf dans les cas suivants :</p>

      <ul>
        <li>Lorsque cela est nécessaire pour exécuter une réservation que vous avez effectuée, par exemple pour partager vos informations de réservation avec notre établissement.</li>
        <li>Lorsque cela est requis par la loi ou en réponse à une demande légale.</li>
      </ul>

      <h3>Conservation des Informations</h3>

      <p>Nous conservons vos informations personnelles aussi longtemps que nécessaire pour atteindre les finalités décrites dans cette politique de confidentialité, à moins que la loi n'exige ou ne permette une période de conservation plus longue.</p>

      <h3>Vos Droits</h3>

      <p>Vous avez le droit d'accéder, de corriger ou de supprimer les informations personnelles que nous détenons vous concernant. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter à l'adresse contact.gustocoofee@gmail.com.</p>

      <h3>Cookies</h3>

      <p>Notre site web peut utiliser des cookies pour améliorer l'expérience des utilisateurs et fournir des fonctionnalités supplémentaires. Vous pouvez configurer votre navigateur pour refuser tous les cookies ou pour vous alerter lorsque des cookies sont envoyés. Veuillez consulter les paramètres de votre navigateur pour en savoir plus sur la gestion des cookies.</p>

      <h3>Modifications de la Politique de Confidentialité</h3>

      <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification prendra effet dès sa publication sur notre site web. Veuillez vérifier régulièrement cette page pour rester informé des éventuelles mises à jour.</p>

      <h3>Contact</h3>

      <p>Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à l'adresse contact.gustocoofee@gmail.com.</p>

      <p>Notez que cette politique de confidentialité est un exemple et peut nécessiter des ajustements en fonction des spécificités de votre site web et de votre entreprise. Il est recommandé de consulter un avocat spécialisé en protection des données pour vous assurer que votre politique de confidentialité est conforme à la législation en vigueur dans votre pays.</p>
    </div>
  </div>
);

PolitiqueConfidentialite.propTypes = {};

PolitiqueConfidentialite.defaultProps = {};

export default PolitiqueConfidentialite;
