import React from 'react';
import PropTypes from 'prop-types';
import './ConditionsUtilisation.css'
import {Helmet} from "react-helmet";

const ConditionsUtilisation = () => (
  <div className="ConditionsUtilisation">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Conditions Générales D'utilisation - Gusto Coffee</title>
      <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/condition-utilisation" />
    </Helmet>
    <div className='container'>
    <h2 className='text-center'>Conditions Générales d'Utilisation</h2>
    <div className='divider'></div>
      <p>
        Les présentes conditions d’utilisation sont conclues entre tout internaute utilisateur du site internet (ci-après dénommé « l’Utilisateur ») et le site « www.gustocoffee.com », exploité par la société Gusto Coffee, SARL société de droit français enregistrée sous le numéro 484 969 250, dont le siège social est situé 10 rue des lilas, 94000 Créteil, France. Le site internet « www.gustocoffee.com » désigne le site internet accessible principalement depuis l’URL https://www.gustocoffee.com et permettant aux utilisateurs de bénéficier des fonctionnalités décrites ci-dessous. Gusto Coffee restreint l’utilisation de son site aux seuls utilisateurs ayant la qualité de particulier et l’utilisant à une fréquence normale. L’utilisation ou l’accès détourné du site est strictement interdit à toute personne physique ou morale utilisant le site pour des besoins professionnels ou dans le cadre d’une activité professionnelle accessoire. Pour les besoins des présentes, le terme « Utilisation » regroupe toutes les opérations effectuées par l’Internaute dès l’accès au site, en ce compris sa simple consultation, quel qu’en soit le dispositif d’accès (notamment, ordinateur, tablette et Smartphone), le type de connexion (connexion privée, connexion d’un tiers ou Wi-Fi public) et le lieu de connexion (depuis la France ou l’étranger). L’utilisation du site implique l’acceptation sans réserve des présentes conditions générales par l'utilisateur. Ces conditions peuvent faire l’objet de modifications. Dès lors, il est entendu que les conditions applicables sont celles en vigueur sur le site au moment de son utilisation.
      </p>

      <h3>Fonctionnalités proposées par le site</h3>
      <ul>
        <li>S'inscrire et se connecter : Les utilisateurs peuvent créer un compte sur le site pour accéder à toutes les fonctionnalités. Ils peuvent se connecter avec leurs identifiants pour accéder à leur compte.</li>
        <li>Parcourir les produits : Les utilisateurs peuvent consulter la gamme de produits disponibles, tels que les machines automatiques, les machines expresso, les moulins à café, les cafés en grains, les cafés en dosette, etc. Ils peuvent voir les détails de chaque produit, y compris les caractéristiques, les descriptions et les prix.</li>
        <li>Effectuer des réservations d'espaces de travail : Les utilisateurs peuvent réserver des espaces de travail collaboratifs dans le coffee-shop Gusto Coffee. Ils peuvent sélectionner le type d'espace souhaité (table dans le salon principal ou salon privé) et le nombre de places nécessaires. Ils peuvent choisir la date et l'heure de réservation, en fonction de la disponibilité.</li>
        <li>Sélectionner des forfaits supplémentaires : Les utilisateurs peuvent ajouter des forfaits supplémentaires à leur réservation d'espace de travail. Ils peuvent choisir entre le forfait "découverte" et le forfait "barista" qui offrent des avantages supplémentaires tels que l'accès à différents types de café et des viennoiseries.</li>
        <li>Effectuer des paiements : Les utilisateurs peuvent procéder au paiement en ligne pour confirmer leur réservation et les forfaits choisis. Le site peut intégrer une passerelle de paiement telle que Stripe pour permettre des transactions sécurisées.</li>
        <li>Consulter et gérer les réservations : Les utilisateurs peuvent accéder à leur compte pour consulter les détails de leurs réservations passées et futures. Ils peuvent modifier ou annuler leurs réservations, en fonction des politiques de réservation du coffee-shop.</li>
        <li>Consulter les informations sur les coffee-shops : Les utilisateurs peuvent accéder aux informations sur les différents coffee-shops de Gusto Coffee, y compris leurs emplacements et leurs horaires d'ouverture. Ils peuvent obtenir des indications sur la manière de s'y rendre et découvrir les services et les espaces de travail proposés.</li>
        <li>Contacter le service client : Les utilisateurs peuvent avoir la possibilité de contacter le service client de Gusto Coffee pour poser des questions, obtenir des informations supplémentaires ou signaler des problèmes.</li>
        <li>Recevoir des actualités et des offres spéciales : Les utilisateurs peuvent s'abonner à la newsletter de Gusto Coffee pour recevoir des mises à jour sur les nouveaux produits, les événements spéciaux et les offres promotionnelles.</li>
      </ul>

      <p>
        La liste des fonctionnalités n’est fournie qu’à titre indicatif. Le Site se réserve le droit d’en ajouter ou d’en supprimer, temporairement ou définitivement, sans l’accord de l’utilisateur.
      </p>

      <h3>Conditions d’utilisation du site</h3>
      <p>
        Le Site est réservé aux personnes majeures ayant la qualité de particulier ainsi qu’aux mineurs titulaires d’une autorisation parentale. En tout état de cause, www.gustocoffee.com encourage toute personne ayant la responsabilité d’un mineur à contrôler l’utilisation du Site par ce dernier. L’accès à certains services proposés par le Site peut être subordonné à la création d’un Compte nécessitant la communication, par l’Utilisateur, d’informations permettant son identification. Au regard des dispositions de la Loi pour la confiance dans l’économie numérique (LCEN) du 6 août 2004 modifiée, www.gustocoffee.com dispose d’une obligation légale de collecte et de conservation de ces données d’identification. L'utilisateur s’engage à transmettre des données exactes et complètes sur son identité et s’interdit d’usurper l’identité d’un tiers quelconque. Dans le cas contraire, le Site se réserve le droit de ne pas confirmer, suspendre ou supprimer le compte.
      </p>
      <h3>Dépôt de contenus sur le site</h3>
      <p>
        L’utilisateur est responsable de tout ce qui peut se produire en son nom et depuis son terminal informatique. L’Utilisateur s’engage à prévenir immédiatement le Site, dans l’hypothèse où il aurait des raisons de croire que son pseudo ou adresse email est, ou peut-être, connu par une personne non autorisée à l’utiliser. Le Site décline toute responsabilité en cas d’utilisation de données personnelles d’un Utilisateur par un tiers non autorisé.
      </p>

      <h3>Comportement sur le site</h3>
      <p>
        L’Utilisateur s’interdit notamment de :
      </p>
      <ul>
        <li>Commettre un quelconque agissement illégal.</li>
        <li>Extraire ou collecter des données personnelles d’utilisateurs du Site par un moyen quelconque.</li>
        <li>Extraire, enregistrer ou exploiter, à d’autres fins que l’unique affichage lors de la navigation sur le présent Site, les contenus de tiers soumis à droits de propriété intellectuelle, relatifs à la vie privée, données personnelles ou droits à image.</li>
        <li>Stocker, diffuser ou publier tout contenu illégal, préjudiciable, injurieux, raciste, incitant à la haine, révisionniste, contraire aux bonnes mœurs, portant atteinte à la vie privée ou portant atteinte aux droits privatifs de tiers, notamment le droit à l’image des personnes et des biens, le droit de propriété intellectuelle ou le droit au respect de la vie privée.</li>
        <li>Stocker, diffuser ou publier, toute information permettant de révéler, directement ou indirectement, ses opinions politiques, philosophiques ou religieuses, son appartenance syndicale, son état de santé ou son orientation sexuelle.</li>
        <li>Déposer des contenus pour le compte d’un tiers.</li>
        <li>Usurper l’identité d’un tiers et/ou publier toute information personnelle d’un tiers.</li>
        <li>Stocker, diffuser ou publier tout contenu pouvant porter atteinte, directement ou indirectement, aux intérêts de Gusto Coffee.</li>
      </ul>
      <p>
        D’une manière générale, l'utilisateur s’engage à s’abstenir de tout comportement contraire à l’éthique sportive ou aux valeurs et intérêts de Gusto Coffee.
      </p>

      <h3>Propriété intellectuelle</h3>
      <p>
        Le Contenu sur le Site est soumis à des droits de propriété et protégé au titre de la propriété intellectuelle, notamment droits d’auteurs, dessins et modèles, marques, noms de domaine, brevets, savoir-faire, logiciels ou bases de données. Gusto Coffee reste propriétaire de l’ensemble de ces Contenus et droits associés. Sur ces contenus, Gusto Coffee accorde aux utilisateurs une licence limitée, non-exclusive, révocable, sans droit de sous-licencier pour les simples accès, navigation et utilisation liés au Site. Cette licence n’accorde aux Utilisateurs aucun autre droit, en particulier aucun droit d’exploitation commerciale de ces contenus. L’ensemble des contenus (notamment textes, commentaires, fichiers, images, photos, vidéos, œuvres, etc…), éventuellement soumis à des droits de propriété, propriété intellectuelle, droit à l’image ou autre droit privatif restent la propriété de l’utilisateur, sous réserve des droits limités accordés par la licence définie ci-dessous à Gusto Coffee ou de l’application, au cas par cas, d’autres conditions particulières préalablement acceptées pour des services spécifiques potentiellement disponibles sur le site de Gusto Coffee.
      </p>

      <h3>Responsabilité</h3>
      <p>
        La consultation et l’utilisation du Site se fait sous la responsabilité de l’utilisateur. Le Site peut héberger des liens vers des sites tiers. En cliquant sur ces liens, l’utilisateur reconnaît que www.gustocoffee.com ne peut garantir le contenu de ceux-ci, et accepte par conséquent d’y accéder à ses propres risques. En conséquence, www.gustocoffee.com ne saurait être tenu responsable des dommages qui résulteraient de l’accès et/ou de l’utilisation du Site et des informations qu’il contient. L’utilisateur est en outre informé que www.gustocoffee.com peut être amené à interrompre momentanément l’accès au Site pour des raisons techniques, notamment pour les raisons de sa maintenance. L’utilisateur accepte ces interruptions et renonce à toute réclamation à ce sujet. L’utilisation du Site par l’utilisateur implique la connaissance et l’acceptation des caractéristiques et des limites des technologies inhérentes à Internet, notamment en ce qui concerne les temps de réponse pour consulter ou interroger le serveur hébergeant le Site, les performances techniques, les risques d’interruption et, plus généralement, tout risque encouru lors de la transmission des données.
      </p>
      <p>
        Par conséquent, Gusto Coffee ne saurait en aucune circonstance être tenue responsable, sans que cette liste ne soit limitative :
      </p>
      <ul>
        <li>De toute information consultée sur le Site qui ne serait pas mise en ligne par www.gustocoffee.com</li>
        <li>De tout dysfonctionnement du réseau empêchant le bon fonctionnement du Site</li>
        <li>De perte de toute donnée</li>
        <li>Du dysfonctionnement de tout logiciel</li>
        <li>Des conséquences de tout virus informatique, bogue (bug), anomalie ou défaillance</li>
        <li>De tout dommage causé à l’ordinateur de l’utilisateur</li>
      </ul>
      <p>
        L’activité de www.gustocoffee.com se limite à mettre en relation les utilisateurs et à héberger les contenus publiés par ces derniers. En sa qualité d’hébergeur, au sens de l’article 6.I.2 de la Loi pour la confiance dans l’économie numérique du 21 juin 2004 modifiée, www.gustocoffee.com ne peut voir sa responsabilité engagée du fait des activités ou des contenus stockés sur son Site. La responsabilité de www.gustocoffee.com se limite à celle prévue par les articles 6.I.2 et suivants de la Loi précitée. Le Site pourra donc supprimer tout contenu illicite ou manifestement illicite sans avertissement préalable, préavis, conformément aux dispositions de l’article 6.I.3 de ladite Loi. En conséquence, www.gustocoffee.com n’est pas tenu d’exercer un contrôle a priori sur la qualité, la sûreté, la véracité ou la licéité des Contenus déposés par les utilisateurs.
      </p>

      <p>
        En utilisant le Site, l’utilisateur accepte de dégager la responsabilité de Gusto Coffee dont www.gustocoffee.com et de le garantir de tout dommage, coûts et frais, directs et indirects, découlant de :
      </p>
      <ul>
        <li>Toute réclamation d’un tiers concernant tout contenu déposé en son nom, notamment pour violation de droits sur le contenu publié par l’utilisateur sur le Site ou délits de presse en rapport avec les éléments envoyés ou publiés sur le Site.</li>
        <li>Toute activité relative à la participation sur le Site qui est contraire aux présentes conditions générales, y compris dans un but frauduleux.</li>
        <li>La violation des présentes Conditions d’utilisation du Site.</li>
      </ul>

      <p>
        Gusto Coffee tient à sensibiliser ses utilisateurs quant à la publication de Contenus, tels que les avis ou autres photos d'hébergement des utilisateurs. www.gustocoffee.com attire l’attention de ses utilisateurs sur la nécessité de publier ou partager des photographies respectueuses et conformes à l’éthique de la nature et aux valeurs de Gusto Coffee. Tout Contenu non conforme sera systématiquement retiré du Site.
      </p>

      <h3>Suppression des contenus déposés par les utilisateurs</h3>
      <p>
        www.gustocoffee.com pourra résilier toute inscription et/ou supprimer tout profil et/ou tout contenu et/ou toute information publiée sur le Site et/ou interdire l’utilisation et/ou l’accès au Site, dès connaissance du non-respect par l’utilisateur des présentes conditions générales d’utilisation ou pour des raisons techniques. Cette modification ou suppression pourra s’effectuer sans préavis, sans notification ou avertissement préalable, à tout moment et à l’entière discrétion de www.gustocoffee.com. Via mail à contact@gustocoffee.fr, chaque utilisateur peut informer le Site, en joignant l’ensemble des précisions nécessaires au traitement de sa demande, qu’un Contenu est présumé publié en violation des présentes conditions d’utilisation.
      </p>
      <p>
        L’utilisateur dispose également de la possibilité de supprimer les contenus déposés sur le Site. Pour ce faire, il doit formuler une demande de retrait du contenu via un mail à envoyer à contact@gustocoffee.fr : Cette demande doit impérativement s’accompagner des informations permettant l’identification dudit contenu. Les informations nécessaires à la suppression sont les suivantes : nom, prénom, adresse email. Toute demande fondée et légitime sera traitée dans les meilleurs délais par www.gustocoffee.com sans que ce dernier ne puisse garantir une suppression immédiate de l’accès au contenu, l’utilisateur reconnaît que www.gustocoffee.com ne saurait engager une quelconque responsabilité à cet égard, notamment du fait du délai nécessaire de traitement de sa demande, de contrainte technique ou d’impossibilité matérielle d’y répondre notamment dans le cas où le contenu aurait été repris par des tiers.
      </p>

      <h3>Intégralité</h3>
      <p>
        Si une ou plusieurs stipulations des conditions générales d’utilisation sont déclarées nulles ou caduques par application d’une loi, d’un règlement ou à la suite d’une décision judiciaire ou administrative définitive, les autres stipulations garderont leur force et leur portée. www.gustocoffee.com fera ses meilleurs efforts pour procéder dès que possible à son remplacement par une stipulation valide et d’une portée la plus proche à l’esprit des présentes. Le fait que l’une des parties n’ait pas exigé l’application d’une clause quelconque des présentes conditions générales, que ce soit de façon permanente ou temporaire, ne pourra en aucun cas être considéré comme une renonciation aux droits de cette partie découlant de ladite clause.
      </p>

      <h3>Droit applicable et juridiction compétente</h3>
      <p>
        Les présentes conditions générales d’utilisation sont régies, interprétées et appliquées conformément au droit français. En cas de litige relatif à la validité, l’interprétation ou l’exécution des présentes conditions générales d’utilisation, les parties conviennent que les tribunaux français seront exclusivement compétents pour en juger, sauf règles de procédure impératives contraires.
      </p>

      <p>
        Fait à Créteil, France, le 19 juillet 2023
      </p>
    </div>
  </div>
);

ConditionsUtilisation.propTypes = {};

ConditionsUtilisation.defaultProps = {};

export default ConditionsUtilisation;
