import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './MdpOublier.css';
import axios from 'axios';
import { FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa';


const MdpOublier = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour gérer le chargement
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const hideAlert = () => {
    setIsAlertVisible(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Envoyer la demande de récupération de mot de passe avec l'e-mail
    console.log('Envoyer une demande de récupération de mot de passe pour :', email);
    try {
      const response = await axios.post('https://gustocoffeeapi.fr/api/reset_token', { email: email });
      setMessage(response.data.message);
      if  (response.data["message"] === "Email de réinitialisation envoyé"){
        setIsLoading(false); // Cacher l'icône de chargement
        setAlertMessage('Email de réinitialisation envoyé');
        setIsAlertVisible(true); // Afficher l'alerte de succès
        setTimeout(hideAlert, 5000);
      }else {
        setIsLoading(false); // Cacher l'icône de chargement
        setAlertMessage(response.data.message); // Message d'erreur
        setIsAlertVisible(true); // Afficher l'alerte d'erreur
        setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
      }
    } catch (error) {
      setMessage('Une erreur s\'est produite. Veuillez réessayer.');
    }
    // Réinitialiser le champ d'e-mail
    setEmail('');
  };

  return (
    <div className='container-lg text-center'>
      <div className='row'>
        <div className='col mdp'>
      <h1>Mot de passe oublié</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="form-label"  htmlFor="email">Adresse e-mail :</label>
          <input
            type="email"
            id="email"
            value={email}
            className="form-control"
            placeholder="Adresse mail"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="d-flex justify-content-center">

        <button className="btn btn-mdp-o btn-lg btn-block mr-lg-2 mb-3 col-10 col-lg-4 my-auto mt-4"type="submit">Envoyer</button>
        </div>
      </form>
      <div className="mt-4">
                <a href="/Identifier" className="lien">
                  Insciption/Connexion
                </a>
            </div>
        </div>
    </div>
    {isLoading && (
        <div className="alert-box-spinner">
          <span className="loader"></span>
        </div>
      )}
      {isAlertVisible && (
        <div
          className={`alert-box alert d-flex align-items-center ${alertMessage.includes('envoyé') ? 'alert-success' : 'alert-danger'
            }`}
          role="alert"
        >
          {/* Utilisez un ternaire pour afficher l'icône en fonction de la classe */}
          {alertMessage.includes('envoyé') ? (
            <FaCheckCircle className="bi flex-shrink-0 me-2" aria-label="Success" />
          ) : (
            <FaExclamationTriangle className="bi flex-shrink-0 me-2" aria-label="Danger" />
          )}
          <div>{alertMessage}</div>
        </div>
      )}
    <p>{message}</p>

    </div>
  );


};

MdpOublier.propTypes = {};

MdpOublier.defaultProps = {};

export default MdpOublier;
