// api.js

import axios from 'axios';

const api = axios.create({
  baseURL: 'https://gustocoffeeapi.fr/api',
});

// Intercepteur pour ajouter le token JWT aux en-têtes des requêtes
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  console.log('Token:', token);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['Content-Type'] = 'application/json'
  return config;
});

export default api;

