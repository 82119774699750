import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TestComponent = () => {
  const [apiResponse, setApiResponse] = useState('');

  useEffect(() => {
    // Effectue l'appel API lorsque le composant est monté
    axios.get('http://127.0.0.1:8000/api/test')
      .then(response => {
        setApiResponse(response.data.message);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <h2>Résultat de l'appel API :</h2>
      <p>{apiResponse}</p>
    </div>
  );
};

export default TestComponent;
