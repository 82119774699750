import './App.css';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home';
import React from 'react';
import TypeReservation from './components/TypeReservation/TypeReservation';
import Reservation from './components/Reservation/Reservation';
import CookieConsent from "react-cookie-consent";
import Identifier from './components/Identifier/Identifier';
import ReservationSalle from './components/ReservationSalle/ReservationSalle';

import MdpOublier from './components/MdpOublier/MdpOublier';
import Nvmdp from './components/Nvmdp/Nvmdp';
import Produits from './components/Produits/Produits';
import MentionsLegales from './components/MentionsLegales/MentionsLegales';
import PolitiqueConfidentialite from './components/PolitiqueConfidentialite/PolitiqueConfidentialite';
import ConditionsVente from './components/ConditionsVente/ConditionsVente';
import ConditionsUtilisation from './components/ConditionsUtilisation/ConditionsUtilisation';
import ApiTest from './components/ApiTestComponent/ApiTestComponent';
import Profile from './components/Profile/Profile';
import { isAuthenticated } from './export/auth';
import CustomDrink from './components/CustomDrinkComponent/CustomDrink';
import NotFound from './components/NotFound/NotFound';

const PrivateRoute = ({ element, ...rest }) => {
    const checkAuthentication = isAuthenticated();
    return checkAuthentication ? element : <Navigate to="/Identifier" />;
};

function App() {
  return (
    <Router>
      <NavBar />
      <CookieConsent
        DisableStyles = { true }
        location="bottom"
        buttonText="Accepter"
        cookieName="myCookieConsent"
        style={{ background: "white" }}
      >
        Ce site utilise des cookies pour améliorer votre expérience.
      </CookieConsent>
      <Routes>
        <Route  path="/" Component={Home} />
        <Route path="/Contact" Component={Contact} />
        <Route path="/TypeDeReservation" Component={TypeReservation} />
        <Route path="/Reservation" element={<PrivateRoute element={<Reservation />} />} />
        <Route path="/ChoixReservation" Component={TypeReservation} />
        <Route path="/Identifier" Component={Identifier} />
        <Route path="/ReservationSalle" element={<PrivateRoute element={<ReservationSalle />} />} />
        <Route path="/MdpOublier" Component={MdpOublier} />
        <Route path="/Nvmdp/:token" Component={Nvmdp} />
        <Route path="/mentions-legales" Component={MentionsLegales} />
        <Route path="/condition-utilisation" Component={ConditionsUtilisation} />
        <Route path="/conditions-de-vente" Component={ConditionsVente} />
        <Route path="/politique-confidentialite" Component={PolitiqueConfidentialite} />
        <Route path="/produits" Component={Produits} />
        <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
        <Route path="/custom-drink" element={<PrivateRoute element={<CustomDrink />} />} />
        <Route path="/apiTest" Component={ApiTest} />
        <Route path="/" element={<App />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
