import React from 'react';
import './Produits.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import coffe1 from '../../assets/produit_coffee_1.webp';
import coffe2 from '../../assets/produit_coffee_2.webp';
import coffe3 from '../../assets/produit_coffee_3.webp';
import access1 from '../../assets/produit_access_1.webp';
import access2 from '../../assets/produit_access_2.webp';
import access3 from '../../assets/produit_access_3.webp';
import machine1 from '../../assets/machine_1.webp';
import machine2 from '../../assets/machine_5.webp';
import machine3 from '../../assets/machine_4.webp';
import { Helmet } from "react-helmet";

const Produits = () => {
    return (
        <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Produit de Café de Qualité - Gusto Coffee</title>
          <meta name="description" content="Découvrez notre gamme de produit de café de haute qualité" />
          <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/produits" />
        </Helmet>
        <div id='produits'>
            <h3 className='text-center'>Nos Produits</h3>
            <div class="divider"></div>
            <div className='container mt-5 mb-5'>
            <Tabs
            defaultActiveKey="coffee"
            id="fill-tab-example"
            className="mb-3"
            fill
          >
            <Tab eventKey="machines" title="Machines">
            <div className='mt-5 container machine'>
                    <h3 className='text-center'>Nos Machines</h3>
                    <div className='divider'></div>
                    <div className='d-flex card-deck justify-content-lg-evenly align-items-center align-items-stretch container flex-column flex-lg-row mt-5'>
                        <div class="card card-produit col-12 col-lg-6 mb-lg-0 mb-5 mx-auto">
                            <img src={machine1} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Le Compact</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>Le parfait compagnon pour les espaces restreints, notre machine à café Compact allie performance et praticité. Malgré sa taille réduite, elle offre des cafés riches en arômes à chaque infusion. Son design élégant s'intègre facilement dans n'importe quel environnement, apportant une touche de sophistication à chaque tasse.</p>
                            </div>
                        </div>
                        <div class="card card-produit col-12 col-lg-6 mb-lg-0 mb-5 mx-auto">
                            <img src={machine2} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Le doublé</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>La machine à café "Le Doublé", comme son nom l'indique, vous permet de doubler le plaisir. Avec la capacité de préparer deux cafés simultanément, elle est idéale pour partager un moment de délice avec un ami ou pour vous offrir une dose d'énergie supplémentaire. Profitez d'une efficacité sans compromis et de la qualité d'un café parfaitement extrait.</p>
                            </div>
                        </div>
                        <div class="card card-produit col-12 col-lg-6 mb-lg-0 mb-5 mx-auto">
                            <img src={machine3} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Le NRV</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>La machine à café NRV est conçue pour les passionnés exigeants et les professionnels de l'art du café. Dotée de fonctionnalités avancées, elle marie la précision à la puissance pour créer des cafés d'une qualité exceptionnelle. Réglages personnalisables, extraction précise et une variété de méthodes – la NRV incarne la pointe de l'innovation pour ceux qui recherchent l'excellence dans chaque tasse.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab>
              <Tab eventKey="coffee" title="Cafés" >
                <div className='mt-5 container'>
                    <h3 className='text-center'>Nos cafés</h3>
                    <div className='divider'></div>
                    <div className='d-flex card-deck justify-content-lg-evenly align-items-center align-items-stretch container flex-column flex-lg-row mt-5'>
                        <div class="card card-produit col-12 col-lg-6 mb-lg-0 mb-5 mx-auto">
                            <img src={coffe1} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Beans Coffee</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>Laissez-vous séduire par l'exotisme de l'Éthiopie avec notre café Beans Coffee. Ses arômes floraux et fruités vous emmènent en voyage vers les hauts plateaux africains, offrant une expérience sensorielle unique.</p>
                            </div>
                        </div>
                        <div class="card card-produit col-12 col-lg-6 mb-lg-0 mb-5 mx-auto">
                            <img src={coffe2} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Battlecreeck Coffee</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>Découvrez l'essence même de la Colombie avec notre café Battlecreeck Coffee. Sa douceur équilibrée et sa subtile acidité vous invitent à savourer le meilleur des montagnes colombiennes à chaque gorgée.</p>
                            </div>
                        </div>
                        <div class="card card-produit col-12 col-lg-6 mb-lg-0 mb-5 mx-auto">
                            <img src={coffe3} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Brooklyn Coffee</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>Plongez dans l'aventure avec notre café Brooklyn Coffee. Ses notes terreuses et épicées évoquent les mystérieuses forêts tropicales de Sumatra, créant une expérience audacieuse et captivante.</p>
                            </div>
                        </div>
                    </div>
                </div>
              </Tab>
              <Tab eventKey="accessoires" title="Accessoires" >
              <div className='mt-5 container'>
                    <h3 className='text-center'>Nos Accessoires</h3>
                    <div className='divider'></div>
                    <div className='d-flex card-deck justify-content-lg-evenly align-items-center align-items-stretch container flex-column flex-lg-row mt-5'>
                        <div class="card card-produit col-12 col-lg-3 mb-lg-0 mb-5 mx-auto">
                            <img src={access1} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Mug - Le Hustle</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>Le mug Hustle est conçu pour les esprits ambitieux qui abordent chaque journée avec détermination. Sa forme ergonomique s'adapte parfaitement à votre main, vous permettant de saisir votre café tout en continuant à avancer. Que vous travailliez dur au bureau ou que vous poursuiviez vos rêves, le mug Hustle sera votre compagnon fidèle.</p>
                            </div>
                        </div>
                        <div class="card card-produit col-12 col-lg-3 mb-lg-0 mb-5 mx-auto">
                            <img src={access2} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Mug - Le Fuel Adventure</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>Embarquez pour de nouvelles aventures avec le mug Fuel Adventure. Ce mug robuste et isolé garde votre café chaud tout en vous accompagnant dans vos escapades en plein air. Conçu pour résister aux conditions les plus exigeantes, il vous assure une source constante d'énergie et de confort, où que votre aventure vous mène.</p>
                            </div>
                        </div>
                        <div class="card card-produit col-12 col-lg-3 mb-lg-0 mb-5 mx-auto">
                            <img src={access3} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <h5 class="card-title text-center text-white">Thermos à café - Le Nomade</h5>
                                <div className='divider'></div>
                                <p className='text-white text-center'>Le Thermos à Café Nomade est plus qu'un simple récipient, c'est une assurance que votre café restera chaud pendant des heures. Sa technologie d'isolation de pointe maintient la température idéale, vous permettant de savourer chaque gorgée comme si elle venait d'être préparée. Idéal pour les professionnels toujours en déplacement, il est le compagnon parfait pour rester productif tout au long de la journée.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab>
          </Tabs>
        </div>
        </div>
        </div>
    );
}
export default Produits;
