import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.css';
import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import logo from '../../assets/gusto_favicon.png';
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../export/auth.js'; 
import LogoutButton from '../LogoutButton.js';
import axios from 'axios';
import { NavDropdown } from 'react-bootstrap';

const NavBar = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);

  useEffect(() => {
    setIsLoggedIn(isAuthenticated());

    // Récupérer le JWT depuis le localStorage
    const jwtToken = localStorage.getItem('jwtToken');
    if (jwtToken) {

      // Appeler l'API pour récupérer le prénom de l'utilisateur en utilisant l'email
      axios.get('https://gustocoffeeapi.fr/api/user', {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      })
      .then((response) => {
        const { firstname } = response.data;
        setUserFirstName(firstname);
      })
      .catch((error) => {
        console.log("Erreur lors de la récupération du prénom de l'utilisateur:", error);
      });
    }
  }, []);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [iconClass, setIconClass] = useState("navbar-toggler-icon");

  const toggleButton = () => {
    setIsButtonClicked(!isButtonClicked);
    setIconClass(isButtonClicked ? "navbar-toggler-icon" : "close-icon");
  };

return(
  <header className="NavBar" data-testid="NavBar">

      <div className="bg-noir barre">
        <div className="head d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="d-flex justify-content-between">
              <div className="col-3 d-flex justify-content-around">
                <a className='orange ico' href="https://www.facebook.com/profile.php?id=100095518058274" target="_blank"><FaFacebook /></a>
                <a className='orange ico' href="https://twitter.com/GustoCoffeParis" target="_blank"><FaTwitter /></a>
                <a className='orange ico' href="https://www.instagram.com/gusto_coffe_paris/" target="_blank"><FaInstagram /></a>
                <a className='orange ico' href="https://www.linkedin.com/in/gusto-coffee-08368a286/" target="_blank"><FaLinkedin /></a>
                <div>
    </div>
              </div>
              <em className="orange">"Votre café, votre espace, votre inspiration."</em>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid no-padding image-container">
        <div className="logo d-flex justify-content-center w-100">
          <img src={logo} alt="Logo" className="img-logo" />
        </div>
<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className='d-flex nav-mobile'>
    <img src={logo} alt="Logo" className="img-logo-mobile" onClick={() => window.location.href = '/'}/>
    <em className="orange slog ">"Votre café, votre espace, votre inspiration."</em>
    <button id='navbar-toggler' className="navbar-toggler mr-2 navbar-toggler-container ml-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleButton}>
      <span className={iconClass}></span>
      {isButtonClicked && <div className="close-icon py-1">✖</div>}
    </button>
  </div>


  <div className="collapse navbar-collapse" id="navbarNav">

    <div className='container link-mobile d-flex justify-content-between'>

      <ul className="navbar-nav">
        <li className="nav-item">
          <Link className="nav-link" to='/'>Accueil</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to='/TypeDeReservation'>Réservation</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to='/produits'>Produits</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to='/Contact'>Contact</Link>
        </li>
        <li className="nav-item dropdown nav-d-none">
                {isLoggedIn ? (
                  <>
                    <li class="nav-item dropdown">
                      <a class="custom-drop dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {`Bonjour, ${userFirstName}`}
                      </a>
                      <ul class="dropdown-menu">
                        <Link className='dropdown-item' to='/Profile'>Profil</Link>
                        <Link className='dropdown-item' to='/TypeDeReservation'>Réservations</Link>
                        <Link className='dropdown-item' to='/custom-drink'>Custom Drinks</Link>
                        <Link className='dropdown-item'><LogoutButton/></Link>
                      </ul>
                    </li>
                  </>
                ) : (
                  <Link className="nav-link" to='/Identifier'>Espace Membre</Link>
                )}
              </li>
      </ul>
      <ul className='navbar-nav nav-m-none'>
      <li className="nav-item d-flex align-items-center">
      {isLoggedIn ? (
                  <>
                    <li class="nav-item dropdown">
                      <a class=" custom-drop dropdown-toggle nav-m-none" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {`Bonjour, ${userFirstName}`}
                      </a>
                      <ul class="dropdown-menu">
                        <Link className='dropdown-item' to='/Profile'>Profil</Link>
                        <Link className='dropdown-item' to='/TypeDeReservation'>Réservations</Link>
                        <Link className='dropdown-item' to='/custom-drink'>Custom Drinks</Link>
                        <Link className='dropdown-item'><LogoutButton/></Link>
                      </ul>
                    </li>
                  </>
                ) : (
                  <Link className="nav-link" to='/Identifier'>Espace Membre</Link>
                )}
            </li>
      </ul>
    </div>
  </div>
</nav>
</div>
</header>
);
};
NavBar.propTypes = {};

NavBar.defaultProps = {};

export default NavBar;