import React from 'react';
import PropTypes from 'prop-types';
import './TypeReservation.css';
import publicReservation from'../../assets/publicReservation.webp'
import privateReservation from'../../assets/privateReservation.webp'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


const TypeReservation = () => (
  <div className="TypeReservation" data-testid="TypeReservation">
    <Helmet>
        <meta charSet="utf-8" />
        <title>Réservez votre espace de travail - Gusto Coffee</title>
        <meta name="description" content="Réservez facilement votre espace de travail dans notre centre professionnel. Choisissez parmi une large gamme d'options et profitez d'un environnement de travail inspirant."/>
        <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/TypeDeReservation" />
      </Helmet>
    <div className='container'>
      <h1 className='h1'>Réservation</h1>
      <div className='divider-gold mt-4'></div>
      <div className='row justify-content-around'>
        <div className='col-12 col-lg-5'>
        <h3>Réservez une place</h3>
          <img src={publicReservation} alt="Réservation public" className='img-fluid img-type-r'/>
          <p>
          Plongez dans notre espace de travail en salle publique, où les interactions stimulent la créativité. Profitez d'une ambiance ouverte et collaborative, parfaite pour les rencontres inspirantes. Que vous préfériez travailler en solo ou en groupe, notre salle publique offre la flexibilité nécessaire à votre productivité. Rejoignez une communauté animée de professionnels variés et découvrez un environnement où les échanges enrichissent les opportunités de réseautage. Notre espace en salle publique vous invite à une expérience de coworking dynamique et épanouissante.
          </p>
          <Link to="/Reservation?type=public" className='submitBtn mx-auto'>Sélectionner</Link>
        </div>
        <div className='col-12 col-lg-5'>
        <div id='divider-none'></div>
          <h3>Réservez une salle</h3>
          <img src={privateReservation} alt="Réservation salle complete" className='img-fluid img-type-r'/>
          <p>
          Optez pour l'exclusivité d'une salle privée pour votre équipe ou vos collaborateurs. Nos salles privées créent une ambiance intime idéale pour des réunions, des séances de réflexion ou des projets nécessitant une confidentialité accrue. Nous proposons cinq salles de quatre places et cinq salles de six places pour répondre à vos besoins. Les salles privées doivent être réservées dans leur intégralité, garantissant une intimité totale. En offrant un espace personnalisé dédié à votre équipe, nos salles privées encouragent la productivité et la collaboration.
          </p>
          <Link to="/ReservationSalle" className='submitBtn mx-auto'>Sélectionner</Link>
        </div>
      </div>
    </div>
  </div>
);


TypeReservation.propTypes = {};

TypeReservation.defaultProps = {};

export default TypeReservation;