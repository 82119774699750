// auth.js
import jwt_decode from 'jwt-decode';
// export const isAuthenticated = () => {
//     const token = localStorage.getItem('jwtToken');
//     if (token) {
//       return true;
//     }
//     return false;
//   };
export const isAuthenticated = () => {
  const token = localStorage.getItem('jwtToken');
  
  // Vérifiez si le token existe et n'est pas expiré
  if (token && !isTokenExpired(token)) {
    return true;
  } else if (token) {
    // Le token a expiré, supprimez-le du local storage
    localStorage.removeItem('jwtToken');

    // Affichez une alerte à l'utilisateur
    alert("Votre session a expiré. Veuillez vous reconnecter.");

    return false; // L'utilisateur n'est pas authentifié
  }

  return false; // L'utilisateur n'est pas authentifié
};

export const isTokenExpired = (token) => {
  const decodedToken = jwt_decode(token);
  const expirationDate = new Date(decodedToken.exp * 1000);
  return expirationDate <= new Date();
};





