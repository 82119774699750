import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import PropTypes from 'prop-types';
import './Home.css';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaArrowDown, FaWifi } from 'react-icons/fa';
import { Fade, Slide } from "react-awesome-reveal";
import { TypeAnimation } from 'react-type-animation';
import imageMain from '../../assets/background_desktop.webp';
import imgDecouverte from '../../assets/forfait_decouverte.webp';
import imageMainMobile from '../../assets/background_main_mobile.webp';
import imgCoffee from '../../assets/cafe.webp'
import coworking from '../../assets/coworking4.webp';
import imgBarista from '../../assets/forfait_barista.webp';
import imgForfaitDegust from '../../assets/forfait_degust.webp';
import imgProduct from '../../assets/employe.webp';
import { Helmet } from "react-helmet";
import { Printer, Projector } from 'react-bootstrap-icons';

const Home = () => {

    return (
        <div id='home'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Bienvenue dans notre espace de coworking et café accueillant - Gusto Coffee</title>
                <meta name="description" content="Découvrez un lieu chaleureux et inspirant pour travailler ou savourer un délicieux café. Réservez dès maintenant votre place dans notre espace de coworking convivial et profitez d'une ambiance propice à la productivité et à la détente." />
                <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/" />
            </Helmet>
            <div className='text-image-container'>
                <img src={imageMain} alt="Logo" className="img-main d-none d-sm-block" />
                <img src={imageMainMobile} alt="Logo" className="img-main img-mobile d-block d-sm-none" />

                <div className="text-overlay container mt-5">
                    <div className="col-md-8 col-sm-12 o-h">
                        <Fade cascade direction='left' triggerOnce='false'>
                            <h2 className='text-top'> Votre espace de travail, votre café de prédilection,</h2>
                        </Fade>
                        <br></br>
                        <Fade cascade direction='right' triggerOnce='false' duration={1200} delay={700}>
                            <h1>Bienvenue chez
                                <br className='m-none'></br>
                                <span className='lemon'> Gusto Coffee !</span>
                            </h1>
                        </Fade>
                        <h3 className='tcd'>
                            <TypeAnimation
                                sequence={[
                                    '',
                                    1800,
                                    'Travaillez,',
                                    500,
                                    'Travaillez, Créez',
                                    500,
                                    'Travaillez, Créez, Dégustez !',
                                    2200,
                                    'Travaillez, Créez,',
                                    500,
                                    'Travaillez,',
                                    500,
                                ]}
                                repeat={Infinity}
                            />
                        </h3>

                        <div className='d-flex flex-column flex-sm-row mt-5 bouton-main'>
                            <button type='button' className="btn btn-orange btn-lg btn-block mr-sm-2 mb-3 col-10 col-sm-5 col-md-5 col-lg-4 my-auto"><Link className='link-home' to='/TypeDeReservation'>Réservez Maintenant</Link></button>
                            <button type='button' className="btn btn-orange btn-lg btn-block mt-0 mb-3 col-10 col-sm-5 col-md-5 col-lg-4 align-items-center"><Link className='link-home' to='/produits'>Nos Produits</Link></button>
                        </div>
                        <div className='text-center div-chevron'>
                            {/* <Slide delay={1500} direction="down" cascade triggerOnce='false'> */}
                            <a className='chevron' href="#about"><FaChevronDown className="chevron-icon" /></a>
                            {/* </Slide> */}
                        </div>

                    </div>
                </div>
            </div>
            <section className='section-h' id="about">
                <div className="container mt-4">
                    <h3 className='text-center'>Qui Sommes-Nous ?</h3>
                    <div className="divider"></div>
                    <Fade direction='up' triggerOnce='false'>
                        <div className="text-center">
                            <p>Gusto Coffee est une société spécialisée dans la vente de produits autour du café. Depuis une dizaine d'années, nous avons implanté des coffee-shops en Italie, en Angleterre, et nous avons récemment ouvert à Paris, à proximité de gare du Nord, avec notre tout nouveau concept de "coffee-shop coworking". Notre objectif est d'offrir à nos clients un espace de travail collaboratif où ils pourront profiter d'un délicieux café et d'un environnement propice à la productivité.</p>
                            <p>Gusto Coffee s'engage à fournir des produits de qualité et un service exceptionnel à tous nos clients. Que vous soyez une entreprise naissante, un freelance ou simplement à la recherche d'un endroit agréable pour travailler, notre coffee-shop coworking est l'endroit idéal pour vous.</p>
                        </div>
                    </Fade>
                </div>
            </section>
            <section id="service" className='section-h bg-dark text-white'>
                <div class="container">
                    <h3 className='text-center pt-4'>Nos Services</h3>
                    <div className="divider"></div>
                    <div className="row mt-5 d-flex align-items-center justify-content-between mb">
                        <div class="col-12 col-lg-7">
                            <Fade direction='left' triggerOnce='false'>
                                <h4><span>Espace Coworking</span></h4>
                                <p><i>Travailler ensemble, partager une tasse de café.</i></p>
                                <p>Chez Gusto Coffee, nous croyons en la valeur du travail collaboratif et en l'importance de partager un bon café. Que vous soyez un entrepreneur en herbe, un freelance ou simplement à la recherche d'un espace de travail convivial, notre coffee-shop coworking est l'endroit idéal pour vous.
                                    Profitez de la flexibilité de nos espaces de travail, découvrez nos délicieux cafés et bénéficiez d'une atmosphère stimulante pour travailler et échanger avec d'autres professionnels.
                                </p>
                            </Fade>
                        </div>
                        <div class="col-12 col-lg-4">
                            <Fade direction='right' triggerOnce='false'>
                                <img src={coworking} alt="Logo" className="img-fluid w-100 box-s" />
                            </Fade>
                        </div>
                    </div>
                    <div class="row mt-5 d-flex align-items-center justify-content-between mb">
                        <div class="ml-auto col-12 col-lg-4 order-last order-lg-first">
                            <Fade direction='left' triggerOnce='false'>
                                <img src={imgForfaitDegust} alt="Logo" className="img-fluid w-100 box-s" />
                            </Fade>
                        </div>
                        <div class="col-12 col-lg-7">
                            <Fade direction='right' triggerOnce='false'>
                                <h4><span>Nos Forfaits Dégustation</span></h4>
                                <p>Pour rendre votre expérience chez Gusto Coffee encore plus agréable, nous proposons deux forfaits spéciaux en plus du coût de la location de l'espace de travail :</p>
                                <ul>
                                    <li>Le forfait Barista</li>
                                    <li>Le forfait Découverte</li>
                                </ul>
                                <p><a href='#forfaits' className='lien-forfait'>Découvrez tout le détail de nos forfaits ! <FaArrowDown /></a></p>
                            </Fade>
                        </div>
                    </div>
                    <div class="row mt-5 d-flex align-items-center mb-2 pb-5 justify-content-between mb">
                        <div class="col-12 col-lg-7">
                            <Fade direction='left' triggerOnce='false'>
                                <h4><span>Nos Produits</span></h4>
                                {/* <p>Découvrez notre large sélection de produits café chez Gusto Coffee. Du café de qualité provenant des meilleurs cultivateurs, aux machines à café haut de gamme, en passant par les accessoires essentiels, nous avons tout ce dont vous avez besoin pour une expérience caféinée exceptionnelle.</p>
                <p>Venez les découvir dans notre coffeeshop Gusto Coffee !</p> */}
                                <p>Chez Gusto Coffee, nous proposons une large gamme de produits de qualité, tous centrés autour du café. Que vous soyez un amateur de café, un connaisseur ou simplement en quête d'une délicieuse tasse de café pour vous accompagner dans votre journée de travail, nous avons ce qu'il vous faut. Voici quelques-uns de nos produits phares :</p>
                                <p><b>Machines automatiques</b> : Découvrez notre sélection de machines automatiques qui vous permettront de préparer un café parfait à tout moment de la journée, chez vous ou au bureau.</p>
                                <p><b>Machines expresso</b> : Plongez dans l'univers des saveurs riches et intenses de notre café avec nos machines expresso haut de gamme. Profitez d'une expérience café exceptionnelle à chaque tasse.</p>
                                <p><b>Moulins à café</b> : Rien de tel que de moudre votre propre café pour en apprécier toute la fraîcheur et les arômes. Découvrez nos moulins à café de qualité pour une expérience café personnalisée.</p>
                                <p><b>Café en grains</b> : Explorez notre sélection de cafés en grains provenant des meilleures régions productrices du monde. Savourez des saveurs uniques et des arômes envoûtants dans chaque tasse.</p>
                                <p><b>Café en dosette</b> : Pour une solution pratique et rapide, optez pour nos dosettes de café. Profitez d'une tasse de café savoureuse en un instant, sans compromis sur la qualité.</p>

                            </Fade>
                        </div>
                        <div class="col-12 col-lg-4">
                            <Fade direction='right' triggerOnce='false'>
                                <img src={imgProduct} alt="Logo" className="img-fluid box-s" />
                            </Fade>
                        </div>
                    </div>
                    <div className='text-center'>
                        <h4>Boostez Votre Productivité : Wifi Haut Débit et Équipements de Pointe</h4>
                        <div className='row mt-5'>
                        <div className='col-12 col-md-4'>
                            <Projector className='icon-tech' />
                            <p>Projection Full HD</p>
                        </div>
                        <div className='col-12 col-md-4'>
                            <FaWifi className='icon-tech'/>
                            <p>Connexion Haut Débit</p>
                        </div>
                        <div className='col-12 col-md-4'>
                            <Printer className='icon-tech'/>
                            <p>Impression à portée de main</p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-h' id="forfaits">
                <div className='container pt-2'>
                    <h3 class="text-center">Nos Forfaits</h3>
                    <div class="divider"></div>
                    <div className='d-flex card-deck justify-content-lg-evenly align-items-center align-items-stretch container flex-column flex-lg-row mt-5'>
                        <div class="card card-forfait col-12 col-lg-6 mb-lg-0 mb-5 mx-auto">
                            <img src={imgDecouverte} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <Slide direction='right' triggerOnce='false' className='text-center'><h5 class="card-title text-center">Forfait Découverte<br />9 €</h5></Slide>
                                <Slide direction='right' triggerOnce='false'><div className='divider'></div></Slide>
                                <Slide direction='right' triggerOnce='false'>
                                    <ul style={{ fontStyle: "italic" }}>
                                        <li className='align-items-center'><p className='mb-0'> 3 cafés du jour à la demande</p></li>
                                        <hr className='orange' />
                                        <li className='align-items-center'><p className='mb-0'> Viennoiseries ou douceurs sucrées à la demande</p></li>
                                    </ul>
                                </Slide>
                            </div>
                        </div>
                        <div class="card card-forfait col-12 col-lg-6 mx-auto">
                            <img src={imgBarista} class="card-img-top" alt="coffee forfait" />
                            <div class="card-body">
                                <Slide direction='right' triggerOnce='false' className='text-center'><h5 class="card-title text-center">Forfait Barista<br />18 €</h5></Slide>
                                <Slide direction='right' triggerOnce='false'><div className='divider'></div></Slide>
                                <Slide direction='right' triggerOnce='false'>
                                    <ul style={{ fontStyle: "italic" }}>
                                        <li className='d-flex align-items-center'><p className='mb-0'> Toute la collection de café à la demande</p></li>
                                        <hr className='orange' />
                                        <li className='d-flex align-items-center'><p className='mb-0'> Viennoiseries ou douceurs sucrées à la demande</p></li>
                                    </ul>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-h' id='coffee'>
            <h3 class="text-center">Notre sélection de cafés</h3>
            <div class="divider"></div>
            <div className='d-flex container justify-content-between mt-desc align-items-center'>
                    <div className='col-md-6 col-12'>
                    <Fade direction='left' triggerOnce='false'>
                        <p>Explorez le monde des saveurs chez <strong className='orange'>Gusto Coffee</strong> grâce à notre sélection exceptionnelle de cafés venant des quatre coins de la planète :</p>

                        <ul className='text-black list-coffee'>
                            <li><strong>Éthiopie Yirgacheffe</strong> : Plongez dans les origines du café avec ce café raffiné en provenance d'Éthiopie. Ses notes florales et fruitées vous transporteront vers les hauts plateaux d'Afrique de l'Est.</li>
                            <li><strong>Colombie Supremo</strong> : Laissez-vous séduire par la douceur et la richesse de ce café colombien. Cultivé dans les montagnes colombiennes, il offre des saveurs équilibrées et une acidité subtile.</li>
                            <li><strong>Costa Rica Tarrazú</strong> : Découvrez l'équilibre parfait entre le corps corsé et les nuances d'agrumes de ce café provenant des montagnes du Costa Rica. Une véritable aventure gustative en une seule tasse.</li>
                            <li><strong>Sumatra Mandheling</strong> : Plongez dans les forêts tropicales de Sumatra avec ce café aux arômes terreux et épicés. Son caractère robuste et sa profondeur en font une expérience unique.</li>
                            <li><strong>Guatemala Antigua</strong> : Appréciez la complexité de ce café guatémaltèque aux notes de chocolat, de caramel et de fruits secs. Cultivé dans la région d'Antigua, il incarne la diversité des terroirs.</li>
                            </ul>

                            <p>Chez <strong className='orange'>Gusto Coffee</strong>, nous vous offrons une invitation à explorer le monde à travers chaque tasse de café. Laissez nos cafés vous transporter vers des destinations lointaines sans quitter le confort de notre coffee shop.</p>
                    </Fade>
                    </div>
                    <div className='col-md-4 d-m-none'>
                    <Fade direction='right' triggerOnce='false'>
                    <img src={imgCoffee} className="coffee img-fluid" alt="coffee" />
                    </Fade>
                    </div>
                </div>
            </section>
            <section id='custom-drink-section'>
                <div className='text-center'>
                <h3>Votre Boisson Sur Mesure !</h3>
                <div className='divider'></div>
                </div>
                <div className='container'>
                <h4>Découvrez notre Système de Boisson sur Mesure</h4>
                <Fade direction='up' triggerOnce='false'>
                    <p>Chez <span className='orange'>Gusto Coffee</span>, nous croyons en la création de moments uniques et savoureux pour nos clients. C'est pourquoi nous sommes ravis de vous présenter notre tout nouveau système de boisson sur mesure, conçu pour répondre à vos goûts et préférences personnels.</p>
                    <p>Imaginez créer votre propre boisson, exactement comme vous l'aimez. Notre système intuitif vous permet de choisir parmi une variété d'ingrédients de première qualité, des classiques intemporels aux saveurs plus audacieuses. Que vous préfériez une boisson chaude réconfortante ou une option rafraîchissante, nous avons tout ce qu'il faut pour satisfaire votre soif de créativité.</p>
                    <p>Voici comment ça fonctionne :</p>
                    <ol>
                        <li>Sélectionnez le type de boisson : Chaud ou froid, c'est à vous de choisir.</li>
                        <li>Personnalisez les ingrédients : Cochez les ingrédients que vous aimez, des essentiels traditionnels aux garnitures gourmandes.</li>
                        <li>Laissez notre IA créative opérer : Une fois que vous avez fait votre choix, notre puissante intelligence artificielle génère pour vous un nom unique et une description succinte pour votre boisson.</li>
                        <li className='list-unstyled'>Montrez la boisson générée aux personnels, ils la prépareront pour vous !</li>
                    </ol>
                    <p>Le résultat ? Une expérience gustative exclusive, parfaitement adaptée à vos préférences. Que vous soyez un amoureux du café, un adepte du chocolat chaud ou que vous cherchiez quelque chose d'unique, notre Système de Boisson sur Mesure transforme chaque gorgée en une aventure sensorielle.</p>
                    <p>Prêt à créer votre propre boisson inoubliable ? Rejoignez-nous dès aujourd'hui et laissez votre palais vous guider vers de nouvelles découvertes.</p>
                    <div className='text-center justify-content-center d-flex mt-5'>
                        <button className='customBtn'><Link className='link-home' to='/custom-drink'>DÉCOUVRIR !</Link></button>
                    </div>
                </Fade>
                </div>
            </section>
            <section className='section-h' id="join">
                <div class="container pt-2">
                    <h3 class="text-center">Réservez Maintenant !</h3>
                    <div class="divider"></div>
                    <div class="row mt-5">
                        <div class="col-12 col-lg-6 text-center br mb-5">
                            <Fade direction='left' triggerOnce='false'>
                                <h5>Réservez votre place</h5>
                                <p class="">
                                Plongez dans notre espace de travail en salle publique, où les interactions stimulent la créativité. Profitez d'une ambiance ouverte et collaborative, parfaite pour les rencontres inspirantes. Que vous préfériez travailler en solo ou en groupe, notre salle publique offre la flexibilité nécessaire à votre productivité. Rejoignez une communauté animée de professionnels variés et découvrez un environnement où les échanges enrichissent les opportunités de réseautage. Notre espace en salle publique vous invite à une expérience de coworking dynamique et épanouissante.
                                </p>
                            </Fade>
                            <div class="col-md-12 d-flex justify-content-center mt">
                                <button class="btn customBtn" onClick={() => window.location.href = "/Reservation"}>Réserver</button>
                            </div>
                        </div>
                        <div id="divider-none"></div>
                        <div class="col-12 col-lg-6 text-center mb-5 professionel">
                            <Fade direction='right' triggerOnce='false'>
                                <h5>Privatisez une salle</h5>
                                <p class="">
                                    Optez pour l'exclusivité d'une salle privée pour votre équipe ou vos collaborateurs. Nos salles privées créent une ambiance intime idéale pour des réunions, des séances de réflexion ou des projets nécessitant une confidentialité accrue. Nous proposons cinq salles de quatre places et cinq salles de six places pour répondre à vos besoins. Les salles privées doivent être réservées dans leur intégralité, garantissant une intimité totale. En offrant un espace personnalisé dédié à votre équipe, nos salles privées encouragent la productivité et la collaboration.
                                </p>
                            </Fade>
                            <div class="col-md-12 d-flex justify-content-center mt">
                                <button class="btn customBtn" onClick={() => window.location.href = "/ReservationSalle"}>Réserver</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};
Home.propTypes = {};

Home.defaultProps = {};

export default Home;