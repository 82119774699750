import React from 'react';
import PropTypes from 'prop-types';
import './MentionsLegales.css';
import { Helmet } from "react-helmet";

const MentionsLegales = () => (
  <div className="MentionsLegales">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Mentions Légales - Gusto Coffee</title>
      <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/mentions-legales" />
    </Helmet>
    <div className='container'>
      <h2 className='text-center'>Mentions Légales</h2>
      <div className='divider'></div>
      <div className="row justify-content-between">
        <div className="col-md-6">
          <h3>Gusto Coffee</h3>
          <p>Localité : 18 Rue de Dunkerque, 75010 Paris, France</p>
          <p>Raison Sociale : SARL</p>
          <p>Code APE : Métiers de la restauration traditionnelle (5610A)</p>
          <p>Capital social : 10 000 €</p>
          <p>N° SIREN : 484 969 250</p>
          <p>Date de création : 2013</p>
          <p>Domaine d’expertise :</p>
          <p>Vente de produits liés au café, gestion de coffee-shops, concept de “coffee-shop coworking” et connaissance approfondie du café.</p>
        </div>
        <div className="col-md-4">
          <h3>Siège social</h3>
          <p>10 rue des lilas, 94000 Créteil, France</p>
          <p>Numéro de téléphone : 06 77 43 06 12</p>
          <p>Mail : philippe.landemaine@gustocoffee.com</p>
          <p>Représentant légal : Monsieur Philippe LANDEMAINE</p>
        </div>
      </div>

      <h3>Hébergement</h3>
      <p>AWS (aws.amazon.com)</p>
      <p>Société immatriculée au registre du commerce sous le numéro RCS : Nanterre B 487 482 143</p>
      <p>Siège social : 52 RUE DU PORT 92000 NANTERRE</p>
      <p>Numéro de téléphone : 08 90 10 93 51</p>
      <p>Directeur Général : M.Ingo STERCK</p>

      <h3>Propriété intellectuelle</h3>
      <p>Le présent site, son nom de domaine, l’ensemble des appellations, des éléments de forme, des programmes d’ordinateur, des bases de données du site, sont protégés pour le monde entier notamment par des droits de propriété intellectuelle. A défaut d’autorisation préalable et écrite de la part de la société Gusto Coffee toute utilisation en tout ou partie de ces éléments est interdite et passible de poursuites judiciaires, notamment pour contrefaçon. Le site a été réalisé par la société “Tech Minds” dont le siège social est 9 Passage Abel Leblanc, 75012 Paris, France.</p>

      <h3>Déclaration CNIL</h3>
      <p>Ce site a fait l’objet d’une déclaration auprès de la CNIL. Les informations collectées sur le site Gusto Coffee sont utilisées uniquement dans le cadre légal prévu en France pour le respect de la vie privée. La Société est le destinataire des données. Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée, vous bénéficiez d’un droit d’accès, de rectification, de suppression des informations qui vous concernent, que vous pouvez exercer en vous adressant à contact@gustocoffee.com</p>

      <h3>Gestion des données personnelles</h3>
      <p>Pour Gusto Coffee, la protection des données personnelles des clients est une priorité au quotidien. Dans le cadre de la réservation des locations, la collecte des informations nominatives est obligatoire pour le traitement et la facturation des réservations. En application de la loi n°2004-801 du 6 août 2004 relative à la protection des données à caractère personnel et modifiant la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, l’utilisateur est informé que les données à caractère personnel le concernant communiquées par lui lors de la création de son compte client et/ou d’une réservation sont enregistrées dans le fichier clients de Gusto Coffee. Ces données font l’objet d’un traitement automatisé destiné à la gestion de son compte client, de sa réservation et à l’envoi d’informations liées, ainsi qu’au respect des dispositions légales ou réglementaires relatives notamment à l’archivage du contrat, à l’exercice du droit de rétractation ou des garanties légales.</p>
      <p>Les données à caractère personnel collectées lors de la création du compte client et/ou de la réservation pourront également être utilisées par Gusto Coffee, par ses services internes, par l’ensemble de ses salariés habilités, à des fins de traitement et de suivi des réservations, d’information des promotions, opérations spéciales et événements à venir par le biais de newsletters, de sondages, d’enquêtes de satisfaction, d’études marketing dans le but d’offrir des offres adaptées, et d’offres et de prospections commerciales.</p>
      <p>Le client pourra toutefois s’y opposer sans frais en adressant un courrier à l’adresse suivante :</p>
      <p>Gusto Coffee</p>
      <p>10 rue des lilas,</p>
      <p>Créteil 94000, France</p>
      <p>Ou bien par mail à l’adresse : contact.gustocoofee@gmail.com</p>
      <p>Le client pourra également se désabonner des newsletters Gusto Coffee, en cliquant sur le lien de désabonnement présent dans le bas de chaque email envoyé. Le client pourra également contacter Gusto Coffee par email à gustocoffee@gmail.com pour demander de retirer son adresse de la liste d’envoi.</p>
    </div>
  </div>
);

MentionsLegales.propTypes = {};

MentionsLegales.defaultProps = {};

export default MentionsLegales;
