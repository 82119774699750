import React from 'react';
import './NotFound.css';

const NotFound = () =>
<div className="d-flex justify-content-center align-items-center not-found">
  <div className='text-center'>
    <h1 className='orange'>404</h1>
    <h3>Page non trouvée</h3>
    <p>Nous sommes désolés, mais la page que vous recherchez n'existe pas.</p>
  </div>
  </div>
export default NotFound;