import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import './Reservation.css';
import detailReservation from '../../assets/cappuccino-coffee.webp';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaChevronRight, FaChevronLeft, FaCheck, FaExclamationTriangle, FaCheckCircle, FaUserPlus } from 'react-icons/fa';
import { TbArmchair2, TbArmchair2Off } from 'react-icons/tb';
import { Slide } from 'react-awesome-reveal';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../CheckoutForm/CheckoutForm.js";

registerLocale('fr', fr);
const Reservation = () => {
  const styleIcon = {
    width: 50,
    height: 50,
  }

  //Déclatation variable
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  const [additionalPersons, setAdditionalPersons] = useState([]);
  const [nombrePersonnes, setNombrePersonnes] = useState(1);

  const [formData, setFormData] = useState({
    date: '',
    departureTime: '',
    duration: '',

    package: '',
    places: [], // Changer le nom de l'état pour "places" et initialiser avec un tableau vide
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [clientSecret, setClientSecret] = useState("");

  const durationPrices = {
    '01:00': 8,
    '02:00': 16,
    '03:00': 24,
    '04:00': 32,
    '05:00': 40,
    '08:00': 64,
  };
  const rows = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  const columns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const stripePromise = loadStripe("pk_test_51NQqveD7lhAQdyL3v6O9uRrQhRtuqpKPSulewL1P0ogWNGZHLiIhC38zIZ19H9IEnzBbnIYQhjd1zmjLG12lVdvv005lX1bTxD");

  const appearance = {
    theme: 'flat',
    rules: {
      '.Label': {
        color: '#ffffff',
      },
    }
  };
  
  
  const options = {
    clientSecret,
    appearance,
  };

    // Gestionnaires de changement pour les champs du formulaire
  const handleInputChange = (event, index) => {
    const { name, value, type } = event.target;
    if (name === 'date') {
      // Convert the date string to a Date object using the parse function
      const dateObject = parse(value, 'dd/MM/yyyy', new Date());
      setFormData({ ...formData, [name]: dateObject });
    }
    else if (name === 'places') {
      let updatedPlaces = [...formData.places];

      if (!updatedPlaces.includes(value)) {
        console.log(addAdditionalPerson.length)
        if (updatedPlaces.length < 1 + additionalPersons.length) {
          updatedPlaces.push(value);
        } else {
          let placeReservation = 1 + additionalPersons.length
          if (placeReservation === 1) {
            setErrorMessage('Votre réservation concerne ' + placeReservation + ' place');
          } else {
            setErrorMessage('Votre réservation concerne ' + placeReservation + ' places');
          }

        }

      } else {
        updatedPlaces = updatedPlaces.filter((place) => place !== value);
      }
      setFormData({ ...formData, places: updatedPlaces });
    } else if (name === 'duration') {
      setFormData({ ...formData, [name]: value });
    }
    else if (name === 'package') {
      if (index !== undefined) {
        const updatedPersons = [...additionalPersons];
        updatedPersons[index] = { ...updatedPersons[index], [name]: value };
        setAdditionalPersons(updatedPersons);
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === 'lastname' || name === 'firstname') {
      if (index !== undefined) {
        const updatedPersons = [...additionalPersons];
        updatedPersons[index] = { ...updatedPersons[index], [name]: value };
        setAdditionalPersons(updatedPersons);
      } else {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Ajout/Suppression personne supplementaire
  const addAdditionalPerson = () => {
    setAdditionalPersons([...additionalPersons, { lastname: '', firstname: '', package: '' }]);
    setNombrePersonnes(nombrePersonnes + 1);
  };
  
  const removeAdditionalPerson = (index) => {
    const updatedPersons = [...additionalPersons];
    updatedPersons.splice(index, 1);
    formData.places.pop()
    console.log(formData.places)
    setAdditionalPersons(updatedPersons);
  };

  //Gestion des differentes étapes 
  const nextStep = () => {
    let error = '';
    // Vérifiez si l'étape actuelle est complète avant de passer à l'étape suivante
    switch (currentStep) {
      case 1:

        if (formData.lastname === '' || formData.firstname === '' || formData.package === '') {
          error = "Veuillez remplir tous les champs pour les informations du client.";
        }
        for (const person of additionalPersons) {
          if (person.lastname === '' || person.firstname === '' || person.package === '') {
            error = "Veuillez remplir tous les champs pour les personnes supplémentaires.";
            break;
          }
        }
        break;
      case 2:
        if (formData.date === '') {
          error = "Veuillez sélectionner une date.";
        }
        break;

      case 3:
        if (formData.departureTime === '') {
          error = "Veuillez sélectionner une heure de début.";
        }
        break;
      case 4:
        if (formData.duration === '') {
          error = "Veuillez sélectionner une durée.";
        }
        break;

      case 5:
        if (formData.places.length === 0) {
          error = "Veuillez sélectionner une place.";
        }
        else if (formData.places.length !== nombrePersonnes) {
          error = "Le nombre de places sélectionnées ne correspond pas au nombre de personnes dans la réservation.";
        }
        break;
      default:
        break;
    }

    if (error !== '') {
      setErrorMessage(error);
    } else {
      setErrorMessage('');
      setCompletedSteps([...completedSteps, currentStep]); // Ajoute l'étape actuelle aux étapes complétées
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, window.innerHeight * 0.2);

    }
  };

  const goToStep = (step) => {
    if (completedSteps.includes(step)) {
      setCurrentStep(step);
    }
  };

  // Fonction pour revenir à l'étape précédente
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  //Alerte
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour gérer le chargement
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const hideAlert = () => {
    setIsAlertVisible(false);
  };
  const [availablePlaces, setAvailablePlaces] = useState([]);
  const [allPlaces, setAllPlaces] = useState([]);

  useEffect(() => {
    // Chargez les places disponibles lorsque l'étape 5 est affichée
    if (currentStep === 5) {
      setIsLoading(true);
      PlacesDisponible();
    }

    // Envoie de l'initiation du paiment à stripe
    if (currentStep === 6) {
      const dataPayment = { price: calculateTotalPrice(formData, additionalPersons) }
      axios
      .post(
       'https://gustocoffeeapi.fr/api/payment',
        dataPayment,
        {}
      )
      .then((response) => response.data)
      .then((data) => {
        setClientSecret(data.clientSecret)
      });
    }
   
  }, [currentStep]);

  // Fonction pour récupérer les places disponibles depuis le backend
  const PlacesDisponible = () => {

    const data = {
      selectedDate: format(formData.date, 'dd/MM/yyyy'),
      selectedStartTime: formData.departureTime,
      selectedDuration: parseInt(formData.duration.slice(0, -1)),
      type: 'Public'
    }

    const token = localStorage.getItem('jwtToken'); // Remplacez avec la clé que vous utilisez pour le token JWT

    // Vérifier que le token JWT est disponible
    if (!token) {
      console.error('Token JWT manquant. Assurez-vous que vous êtes connecté.');
      return;
    }
    axios
      .post(
        'https://gustocoffeeapi.fr/api/places_disponibles',
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        // Utilisez forEach pour filtrer les places disponibles dont isAvailable est false
        setAllPlaces(data)
        const filteredPlaces = [];
        data.forEach((place) => {

          if (!place.isAvailable) {
            filteredPlaces.push(place);
          }
        });
        setAvailablePlaces(filteredPlaces);
        setIsLoading(false);

      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des places disponibles :', error);
        setAvailablePlaces([]);
        setIsLoading(false);
      });
  };

  const handleReservationSuccess =() => {
    setIsLoading(true);
    const data = {
      reservation_date: format(formData.date, 'dd/MM/yyyy'),
      reservation_start_time: formData.departureTime,
      reservation_duration: formData.duration,
      price: calculateTotalPrice(formData, additionalPersons),
      reservation_details: [
        { roomtype_id: formData.places[0], packages_id: formData.package },
        ...additionalPersons.map((person, index) => ({
          roomtype_id: formData.places[index + 1], packages_id: person.package
        }))
      ]
    };

    const token = localStorage.getItem('jwtToken'); 

    // Vérifier que le token JWT est disponible
    if (!token) {
      console.error('Token JWT manquant. Assurez-vous que vous êtes connecté.');
      return;
    }

    axios.post('https://gustocoffeeapi.fr/api/reservations', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        if (response.status === 201) {
          setIsLoading(false); // Cacher l'icône de chargement
          setAlertMessage('Réservation réussie ! Vous allez recevoir une confirmation par e-mail.');
          setIsAlertVisible(true); // Afficher l'alerte de succès
          setTimeout(() => {
            setIsAlertVisible(false); // Masquer l'alerte après 5 secondes (2000 millisecondes)
            window.location.href = "https://www.dsp-devo21-ob-kk-et-abm.fr/Profile";
          }, 2000);
        } else {
          setIsLoading(false); // Cacher l'icône de chargement
          setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
          setIsAlertVisible(true); // Afficher l'alerte d'erreur
          setTimeout(() => {
            setIsAlertVisible(false); // Masquer l'alerte après 5 secondes (2000 millisecondes)
            window.location.href = "https://www.dsp-devo21-ob-kk-et-abm.fr/";
          }, 2000);
        }
      })
      .catch((error) => {
        setIsLoading(false); // Cacher l'icône de chargement
        setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
        setIsAlertVisible(true); // Afficher l'alerte d'erreur
        setTimeout(() => {
            setIsAlertVisible(false); // Masquer l'alerte après 5 secondes (2000 millisecondes)
            window.location.href = "https://www.dsp-devo21-ob-kk-et-abm.fr";
          }, 2000);
      });
      
  };

  const calculateTotalPrice = (formData, additionalPersons) => {
    const durationPrice = durationPrices[formData.duration] || 0; // Default to 0 if duration is not found
    
    let additionalCost = 0;
    // Calcul du prix du forfait
    if (formData.package === 'Découverte') {
      additionalCost += 9;
    } else if (formData.package === 'Barista') {
      additionalCost += 18;
    }

    // Calcul des forfait additionnels 
    additionalPersons.forEach(person => {
      if (person.package === 'Découverte') {
        additionalCost += 9;
      } else if (person.package === 'Barista') {
        additionalCost += 18;
      }
    });
    const totalPrice = (durationPrice * (additionalPersons.length + 1) ) + additionalCost; // +1 pour le client principale qui fait la réservation
    return totalPrice;
  };
  
  const isDateSelectable = (date) => {
    // Vérifiez si la date est passée
    const currentDate = new Date();
    return date >= currentDate;
  };

  const isDateDisabled = (date) => {
    const currentDate = new Date();
    const isPastDate = date < currentDate;

    // Ajouter d'autres dates à désactiver si nécessaire
    const additionalDisabledDates = [
      new Date(2023, 5, 25), // Exemple de date à désactiver
      new Date(2023, 5, 30), // Exemple de date à désactiver
    ];

    // Vérifier si la date est dans la liste des dates désactivées
    const isDisabled = additionalDisabledDates.some((disabledDate) => date.toDateString() === disabledDate.toDateString());

    return isPastDate || isDisabled;
  };

  // Differente etape pour la réservation 
  const renderProgressBar = () => {
    const steps = [

      { number: 1, label: 'Personne(s)' },
      { number: 2, label: 'Date' },
      { number: 3, label: 'Début' },
      { number: 4, label: 'Durée' },
      { number: 5, label: 'Place' },
      { number: 6, label: 'Résumé' },
      { number: 7, label: 'Paiement' },
    ];

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Réservez votre place de coworking ou votre table au café en quelques clics - Gusto Coffee</title>
          <meta name="description" content="Facilitez-vous la vie en réservant votre place de coworking ou votre table dans notre café en ligne. Profitez d'une réservation simple et rapide, et garantissez-vous un espace confortable pour travailler ou savourer votre café préféré. Réservez dès maintenant !" />
          <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/Reservation" />
        </Helmet>
        <div className="customProgress-bar">
          {steps.map((step, index) => (
            <React.Fragment key={step.number}>
              <div
                className={`progress-step ${currentStep === step.number ? 'active' : ''} ${completedSteps.includes(step.number) ? 'completed' : ''}`}
                onClick={() => goToStep(step.number)}
              >
                <div className="step-label">{step.label}</div>
              </div>
              {index !== steps.length - 1 && <div className="progress-bar-line"></div>}
            </React.Fragment>
          ))}
        </div>

        <div className="container-m-bar container">
          {steps.map((step, index) => (
            <React.Fragment key={step.number}>
              <div className="row-m-bar">
                <div className="bloc-bar">
                  <p className="label-m">{step.label}</p>
                  <div
                    className={`circle ${currentStep === step.number ? 'active' : ''} ${completedSteps.includes(step.number) ? 'completed' : ''}`}
                    onClick={() => goToStep(step.number)}
                  >
                    {completedSteps.includes(step.number) ? <FaCheck /> : step.number}
                  </div>
                </div>
              </div>
              {index !== steps.length - 1 && <div className={`line ${completedSteps.includes(step.number) ? 'completed' : ''}`}></div>}
            </React.Fragment>
          ))}
        </div>
      </div>
    );

  };

  // Rendu conditionnel en fonction de l'étape actuelle
  const renderStep = () => {
    switch (currentStep) {
      case 1:

        return (
          <div className='step-one'>
            <h2 className='text-center text-white h2-bis'>Étape 1 : Veuillez saisir vos informations</h2>
            <div className='divider'></div>
            <label className="form-label" for="lastname">Nom<span className="orange"></span></label>
            <input className='form-control'
              type="text"
              id='lastname'
              name="lastname"
              placeholder="Votre Nom"
              value={formData.lastname}
              onChange={(event) => handleInputChange(event)}
            />
            <label className="form-label" for="firstname">Prénom<span className="orange"></span></label>
            <input className='form-control'
              type="text"
              id='firstname'
              name="firstname"
              placeholder="Votre Prénom"
              value={formData.firstname}
              onChange={(event) => handleInputChange(event)}
            />
            <label className="form-label" for="package">Forfait<span className="orange"></span></label>
            <select className='form-select mb-5'
              name="package"
              id='package'
              value={formData.package}
              onChange={(event) => handleInputChange(event)}
            >
              <option value="">Sélectionnez un forfait</option>
              <option value="Aucun">Aucun</option>
              <option value="Découverte">Forfait Découverte (9€)</option>
              <option value="Barista">Forfait Barista (18€)</option>
            </select>


            <h2 className='custom-h2 mt-4 mb-3 personne-n'>Personnes supplémentaires :</h2>
            {additionalPersons.map((person, index) => (
              <div key={index}>
                <div className='justify-content-between d-flex mt-4 mb-2'>
                  <h5 className='text-white text-start'> Personne n° {index + 2}</h5><a className='deleteBtn' href="#" onClick={(e) => { e.preventDefault(); removeAdditionalPerson(index); }}>✖ Retirer</a>
                </div>
                <label className="form-label" for="lastname">Nom<span className="orange"></span></label>
                <input className='form-control'
                  type="text"
                  id='lastname'
                  name="lastname"
                  placeholder="Nom"
                  value={person.lastname}
                  onChange={(event) => handleInputChange(event, index)}
                />
                <label className="form-label" for="firstname">Prénom<span className="orange"></span></label>
                <input className='form-control'
                  type="text"
                  id='firstname'
                  name="firstname"
                  placeholder="Prénom"
                  value={person.firstname}
                  onChange={(event) => handleInputChange(event, index)}
                />
                <label className="form-label" for="package">Forfait<span className="orange"></span></label>
                <select className='form-select mb-5'
                  name="package"
                  value={person.package}
                  onChange={(event) => handleInputChange(event, index)}
                >
                  <option value="">Sélectionnez un forfait</option>
                  <option value="Aucun">Aucun</option>
                  <option value=" Découverte"> Forfait Découverte (9€)</option>
                  <option value="Barista">Forfait Barista (15€)</option>
                </select>
              </div>
            ))}
            <Slide direction="up" duration={200} className='d-flex justify-content-center'>
              {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
            </Slide>
            <div className='col-12 col-md-12 d-flex bottomContainer'>
              <div class="d-grid gap-2 col-6">
                <button className="btn-add-p" type="button" onClick={addAdditionalPerson}>
                  <span className="btn-text">Ajouter une personne</span>
                  <FaUserPlus className="btn-icon-p" />
                </button>
              </div>
              <div className='ml-auto'>
                <button className='customBtn next second-btn' onClick={nextStep}>Suivant<span className='chevron-right'><FaChevronRight /></span></button>
              </div>

            </div>

          </div>
        );
      case 2:
        return (
          <div>
            {/* Formulaire pour l'étape 1 */}
            <div className=''>

              <h2 className=' text-center text-white h2-bis'>Étape 2 : Choisissez la date de la réservation </h2>
              <div className='divider'></div>
              <div className='row'>
                <div className="col-auto mx-auto">
                  <DatePicker
                    locale="fr"
                    selected={formData.date}
                    onChange={(date) => {
                      // Convert the date to a formatted string before setting it in the state
                      const formattedDate = format(date, 'dd/MM/yyyy');
                      handleInputChange({ target: { name: 'date', value: formattedDate } });
                    }}
                    dateFormat="dd/MM/yyyy"
                    inline
                    minDate={new Date()} // Désactive les dates passées
                    filterDate={isDateSelectable} // Sélectionne uniquement les dates non passées
                    dayClassName={(date) => {
                      const classNames = [];
                      if (!isDateSelectable(date)) {
                        classNames.push('disabled-date');
                      }
                      return classNames.join(' ');
                    }}
                    dayPickerProps={{
                      disabledDays: isDateDisabled,
                    }}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>

              <Slide direction="up" duration={200} className='d-flex justify-content-center'>
                {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
              </Slide>
              <div className='col-12 col-md-12 bottomContainer'>
                <div className='d-flex justify-content-between'>
                  <button className='customBtn add' onClick={prevStep}>
                    <span className='chevron-left'><FaChevronLeft /></span>Retour</button>
                  <button className='customBtn next second-btn' onClick={nextStep}>Suivant<span className='chevron-right'><FaChevronRight /></span></button>
                </div>

              </div>
            </div>

          </div>
        );

      case 3:
        const hours = ['08:00', '9:00', '10:00', '13:00', '13:30', '14:00'];
        return (
          <div className='container heure'>
            <h2 className='text-white text-center h2-bis'>Étape 3 : À quelle heure doit commencer votre réservation ?</h2>
            <div className='divider'></div>
            <div className='row justify-content-center contain-card'>
              <div className='col-12 col-lg-8 text-center'>
                <div className='row row-cols-3 row-cols-lg-6 justify-content-between cardContainer'>
                  {hours.map((hour) => (
                    <div className='col d-flex justify-content-center' key={hour}>
                      <button
                        className={`custom-card ${formData.departureTime === hour ? 'selected' : ''}`}
                        onClick={() => handleInputChange({ target: { name: 'departureTime', value: hour } })}
                      >
                        <div className='my-auto mx-auto text-center'>{hour}</div>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <Slide direction="up" duration={200} className='d-flex justify-content-center'>
                {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
              </Slide>
              <div className='col-12 col-md-12 d-flex bottomContainer'>
                <div className='d-flex'>
                  <button className='customBtn add' onClick={prevStep}>
                    <span className='chevron-left'><FaChevronLeft /></span>Retour
                  </button>
                </div>
                <div className='ml-auto'>
                  <button className='customBtn next second-btn' onClick={nextStep}>
                    Suivant<span className='chevron-right'><FaChevronRight /></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

        );
      case 4:
        const duration = ['01:00', '02:00', '03:00', '04:00', '05:00', '08:00'];
        return (
          <div className='container heure'>
            <h2 className='text-white text-center h2-bis'>Étape 4 : Combien de temps dure votre réservation ?</h2>
            <div className='divider'></div>
            <div className='row justify-content-center contain-card'>
              <div className='col-12 col-lg-8 text-center'>
                <div className='row row-cols-3 row-cols-lg-6 justify-content-between cardContainer'>
                  {duration.map((hour) => (
                    <div className='col d-flex justify-content-center' key={hour}>
                      <button
                        className={`custom-card ${formData.duration === hour ? 'selected' : ''}`}
                        onClick={() => handleInputChange({ target: { name: 'duration', value: hour } })}
                      >
                        <div className='my-auto mx-auto text-center'>{hour}</div>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Slide direction="up" duration={200} className='d-flex justify-content-center'>
              {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
            </Slide>
            <div className='col-12 col-md-12 d-flex bottomContainer'>
              <div className='d-flex'>
                <button className='customBtn add' onClick={prevStep}>
                  <span className='chevron-left'><FaChevronLeft /></span>Retour
                </button>
              </div>
              <div className='ml-auto'>
                <button className='customBtn next second-btn' onClick={nextStep}>
                  Suivant<span className='chevron-right'><FaChevronRight /></span>
                </button>
              </div>
            </div>
          </div>

        );

      case 5:
        return (
          <div>
            <div className=''>
              <h2 className='text-white text-center h2-bis'>Étape 5 : Choisissez votre/vos place(s)</h2>
              <div className='divider'></div>
              <div className="d-flex justify-content-center">
                <div className="seat-container">
                  {isLoading && (
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  {Array.from({ length: 10 }, (_, rowIndex) => (
                    <Row key={rowIndex} className={`seat-row letter-${rows[rowIndex]}`}>
                      {columns.map((column) => {
                        const placeIndex = rowIndex * 12 + column - 1; // Index dans allPlaces
                        //console.log(allPlaces)
                        if (placeIndex < allPlaces.length) {
                          const place = allPlaces[placeIndex];
                          const isPlaceSelected = formData.places.includes(place.name);
                          const isPlaceAvailable = !place.isAvailable;
                          return (
                            <Col key={column} className="seat-col">
                              <button
                                className={`cardSeat ${isPlaceSelected ? 'selected' : ''} ${column === 12 ? 'last-column' : ''} btn-chair`}
                                onClick={() => handleInputChange({ target: { name: 'places', value: place.name } })}
                                disabled={isPlaceAvailable}
                              >
                                {/* Seat Label */}
                                <span className="seat-label">{place.name}</span>
                                {/* Icon */}
                                <div className="icon-container">
                                  {isPlaceAvailable ? (
                                    <TbArmchair2Off style={styleIcon} className="TbArmchair2Off-icon" />
                                  ) : (
                                    <TbArmchair2 style={styleIcon} className="TbArmchair2-icon" />
                                  )}
                                </div>
                              </button>
                            </Col>
                          );
                        } else {
                          return null; // Pour les emplacements vides à la fin
                        }
                      })}
                    </Row>
                  ))}
                </div>

              </div>
              <Slide direction="up" duration={200} className='d-flex justify-content-center'>
                {errorMessage && <p className="error-message text-white mt-4"><span className='orange' style={{ marginRight: "5px" }}>*</span>{errorMessage}</p>}
              </Slide>
              <div className='col-12 col-md-12 d-flex bottomContainer'>
                <div className='d-flex'>
                  <button className='customBtn add' onClick={prevStep}>
                    <span className='chevron-left'><FaChevronLeft /></span>Retour</button>
                </div>
                <div className='ml-auto'>
                  <button className='customBtn next second-btn' onClick={nextStep}>Suivant<span className='chevron-right'><FaChevronRight /></span></button>
                </div>
              </div>

            </div>
          </div>
        );
      case 6:
        const formattedDate = format(formData.date, 'dd/MM/yyyy');
        const durationPrice = durationPrices[formData.duration];
        // console.log(formData.places)
        return (
          <div className=''>
            {/* Résumé et validation */}
            <h2 className='text-white text-center h2-bis'> Récapitulatif de la réservation</h2>
            <div className='divider'></div>
            <div className='container-resume'>
              <div className="card">
                <img src={detailReservation} alt="Détails réservation" className='card-img-top' />
                <div className="card-body text-center">
                  <h5 className="card-title">Détails de votre réservation</h5>
                  <p className='card-text'><u>Date</u> :  <b>{formattedDate}</b></p>
                  <p className='card-text'><u>Heure de début</u> : <b>{formData.departureTime}</b></p>
                  <p className='card-text'><u>Durée</u> : <b>{formData.duration}</b></p>
                  <p className='card-text'><u>Prix</u> : <b>{calculateTotalPrice(formData, additionalPersons)} €</b></p>
                  <hr></hr>
                  <h5 className="card-title">Personne 1</h5>
                  <p><u>Nom</u> : <b>{formData.lastname}</b></p>
                  <p><u>Prénom</u> : <b>{formData.firstname}</b></p>
                  <p><u>Forfait</u> : <b>{formData.package}</b></p>
                  <p><u>Place</u> : <b>{formData.places[0]}</b></p>
                  {additionalPersons.map((person, index) => (
                    <div key={index}>
                      <hr></hr>
                      <h5 className="card-title">Personne {index + 2}</h5>
                      <p><u>Nom</u> : <b>{person.lastname}</b></p>
                      <p><u>Prénom</u> : <b>{person.firstname}</b></p>
                      <p><u>Forfait</u> : <b>{person.package}</b></p>
                      <p><u>Place</u> : <b>{formData.places[index + 1]}</b></p>
                    </div>
                  ))}
                  <a className="btn customBtn submit-book" onClick={nextStep} style={{ marginLeft: 'auto' }}>Valider <span className='chevron-right'><FaCheck /></span></a>
                </div>
              </div>

              <div className='col-12 col-md-12 d-flex bottomContainer'>
                <div className='d-flex'>
                  <button className='customBtn add' onClick={prevStep}>
                    <span className='chevron-left'><FaChevronLeft /></span>Retour</button>
                </div>
              </div>
            </div>
            {isLoading && (
              <div className="alert-box-spinner">
                <span className="loader"></span>
              </div>
            )}
            {isAlertVisible && (
              <div
                className={`alert-box alert d-flex align-items-center ${alertMessage.includes('réussi') ? 'alert-success' : 'alert-danger'
                  }`}
                role="alert">
                {alertMessage.includes('réussi') ? (
                  <FaCheckCircle className="bi flex-shrink-0 me-2" aria-label="Success" />
                ) : (
                  <FaExclamationTriangle className="bi flex-shrink-0 me-2" aria-label="Danger" />
                )}
                <div>{alertMessage}</div>
              </div>
            )} 
          </div>
        );
        case 7:
          
          return (
            <div className=''>
              {/* Résumé et validation */}
              <h2 className='text-white text-center h2-bis'> Paiement de la réservation</h2>
              <div className='divider'></div>
              <div className='container-resume'>
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm onPaymentSuccess={handleReservationSuccess } />
                    </Elements>
                  )}
                  <div className="col-5">
              <p className='text-white'>
                <span className="orange">* </span>Utiliser le numéro de carte suivant pour faire le test de paiement :  
                <code className='orange'> 4242 4242 4242 4242</code> (Visa)
              </p>
              <p className='text-white'> Plus d'infos sur la <a href="https://stripe.com/docs/testing#cards" target="_blank" rel="noopener noreferrer"> documentation Stripe </a> </p>
            </div>
                </div>
  
                <div className='col-12 col-md-12 d-flex bottomContainer'>
                  <div className='d-flex'>
                    <button className='customBtn add' onClick={prevStep}>
                      <span className='chevron-left'><FaChevronLeft /></span>Retour</button>
                  </div>
                </div>
              {isLoading && (
                <div className="alert-box-spinner">
                  <span className="loader"></span>
                </div>
              )}
              {isAlertVisible && (
                <div
                  className={`alert-box alert d-flex align-items-center ${alertMessage.includes('réussi') ? 'alert-success' : 'alert-danger'
                    }`}
                  role="alert">
                  {alertMessage.includes('réussi') ? (
                    <FaCheckCircle className="bi flex-shrink-0 me-2" aria-label="Success" />
                  ) : (
                    <FaExclamationTriangle className="bi flex-shrink-0 me-2" aria-label="Danger" />
                  )}
                  <div>{alertMessage}</div>
                </div>
              )} 
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <div className='reservation-f container'>
      <h1 className='text-center'>Réservation dans la salle principale</h1>
      <div className="divider-gold mt-4 mb-lg-5 mb-4"></div>
      <Link className='iconBack' to="/TypeDeReservation">
        <span className='ml-arrow-back'><FaArrowLeft /></span>
        <span className='back-hover' style={{ marginLeft: '5px' }}>Réserver une salle ?</span>
      </Link>
      <div className='bar-d'>{renderProgressBar()}</div>
      <section id='reservation-section'>
        <div className="row justify-content-center">
          <div id='reservation-formulaire' className="col-lg-10">
            <div className="row">
              <div className='bar-mobile'>{renderProgressBar()}</div>
              {renderStep()}
            </div>
          </div>
        </div>
      </section>
    </div>

  );

};

export default Reservation;
