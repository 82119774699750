// LogoutButton.js

import React from 'react';
import axios from 'axios';
const LogoutButton = () => {
  const handleLogout = () => {
    // Effectuer la déconnexion en appelant la route API de déconnexion
    axios.post('https://gustocoffeeapi.fr/api/logout')
      .then(() => {
        // Supprimer le token JWT du local storage (ou des cookies)
        localStorage.removeItem('jwtToken');
        // Rediriger l'utilisateur vers la page de connexion
        window.location.href = '/Identifier';
      })
      .catch((error) => {
        // Gérer les erreurs si nécessaire
        console.error(error);
      });
  };

  return (
    // <button className="btn btn-danger" onClick={handleLogout}>
    //   Déconnexion
    // </button>
    <a href="#" className='text-danger' onClick={handleLogout}>Déconnexion</a>
  );
};

export default LogoutButton;
