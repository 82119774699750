import React from 'react';
import PropTypes from 'prop-types';
import './ConditionsVente.css';
import {Helmet} from "react-helmet";

const ConditionsVente = () => (
  <div className="ConditionsVente">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Conditions de ventes - Gusto Coffee</title>
      <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/conditions-de-vente" />
    </Helmet>
    <div className='container'>
      <h2 className='text-center'>Conditions de Vente</h2>
      <div className='divider'></div>
      <h3>Réservation d'Espace de Travail</h3>
      <ol>
        <li>
          Disponibilité : Les réservations d'espaces de travail sont soumises à disponibilité. Nous ferons de notre mieux pour répondre à vos demandes de réservation, mais nous ne pouvons pas garantir une disponibilité totale à tout moment.
        </li>
        <li>
          Confirmation : Une fois que vous avez effectué une réservation, nous vous enverrons une confirmation par e-mail. Veuillez vérifier les détails de la réservation dans l'e-mail de confirmation et nous contacter immédiatement en cas d'erreur ou de question.
        </li>
        <li>
          Groupement de Places : Lorsque vous réservez plusieurs places, nous ferons tous les efforts possibles pour les regrouper à une même table. Cependant, cela dépendra de la disponibilité et nous ne pouvons pas garantir que toutes les places seront regroupées.
        </li>
        <li>
          Forfaits : Si vous choisissez de souscrire à l'un de nos forfaits, veuillez noter que les services inclus dans chaque forfait sont spécifiés sur notre site web. Les forfaits peuvent être réservés en complément de l'espace de travail.
        </li>
        <li>
          Tarifs et Paiement : Les tarifs des espaces de travail et des forfaits sont indiqués sur notre site web. Les paiements peuvent être effectués en ligne lors de la réservation. Les paiements en ligne sont sécurisés et gérés par des prestataires de services de paiement tiers.
        </li>
        <li>
          Annulation : Les annulations de réservations d'espaces de travail doivent être effectuées au moins 48 heures avant la date de réservation prévue pour bénéficier d'un remboursement complet. Les annulations effectuées après cette période peuvent être soumises à des frais d'annulation.
        </li>
      </ol>

      <h3>Forfaits et Services Additionnels</h3>
      <ol>
        <li>
          Accès aux Services : Les forfaits incluent les services spécifiés sur notre site web. Les forfaits peuvent être utilisés uniquement par le titulaire du forfait et ne peuvent pas être transférés ou partagés avec d'autres personnes.
        </li>
        <li>
          Validité : La durée de validité de chaque forfait est indiquée sur notre site web. Les forfaits doivent être utilisés pendant leur période de validité et ne peuvent pas être utilisés après leur expiration.
        </li>
        <li>
          Modification : Les forfaits peuvent être modifiés ou résiliés par la société Gusto Coffee à tout moment. En cas de modification ou de résiliation d'un forfait auquel vous avez souscrit, nous vous en informerons par e-mail et vous proposerons des alternatives si possible.
        </li>
      </ol>

      <h3>Responsabilité</h3>
      <p>La société Gusto Coffee s'efforce de fournir des informations précises et à jour sur son site web, mais ne peut garantir l'exactitude ou l'exhaustivité des informations fournies. Nous ne serons pas responsables des erreurs ou omissions dans les informations fournies sur notre site web.</p>
      <p>Nous ne serons pas responsables des dommages directs, indirects, spéciaux ou consécutifs résultant de l'utilisation de notre site web ou de la réservation d'espaces de travail ou de forfaits.</p>

      <h3>Modification des Conditions de Vente</h3>
      <p>Nous nous réservons le droit de modifier les présentes conditions de vente à tout moment. Toute modification prendra effet dès sa publication sur notre site web. Veuillez vérifier régulièrement cette page pour rester informé des éventuelles mises à jour.</p>

      <h3>Contact</h3>
      <p>Si vous avez des questions concernant les conditions de vente, veuillez nous contacter à l'adresse contact.gustocoofee@gmail.com.</p>
    </div>
  </div>
);

ConditionsVente.propTypes = {};

ConditionsVente.defaultProps = {};

export default ConditionsVente;
