import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Profile.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FaPen, FaCheckSquare, FaHourglassHalf, FaUserAltSlash } from 'react-icons/fa';
import Swal from 'sweetalert2';

const ProfileComponent = () => {
  const [profileData, setProfileData] = useState(null);
  const [editableFields, setEditableFields] = useState({
    firstname: false,
    lastname: false,
    email: false,
  });
  const [editedData, setEditedData] = useState({}); // Nouvel état pour stocker les données éditées
  const [reservations, setReservations] = useState([]);
  const [editableReservation, setEditableReservation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountDisabled, setIsAccountDisabled] = useState(false);

  useEffect(() => {
    // Récupérer le token JWT du local storage
    const token = localStorage.getItem('jwtToken');

    // Vérifier que le token JWT est disponible
    if (!token) {
      console.error('Token JWT manquant. Assurez-vous que vous êtes connecté.');
      return;
    }

    // Faire une requête pour récupérer les données du profil
    axios
      .get('https://gustocoffeeapi.fr/api/profile', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        setProfileData(response.data);
        setEditedData(response.data); // Initialisez editedData avec les données du profil
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données du profil :', error);
      });
  }, []);

  useEffect(() => {
    fetchReservations();
  }, []);

  const fetchReservations = () => {
    const token = localStorage.getItem('jwtToken');
    axios.get('https://gustocoffeeapi.fr/api/reservations', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        const formattedReservations = response.data.map(reservation => ({
          ...reservation,
          reservationDate: reformatDate(reservation.reservationDate),
          reservationStartTime: reformatTime(reservation.reservationStartTime)
        }));
        setReservations(formattedReservations);
      })
      .catch(error => {
        console.error('Error fetching reservations:', error);
      });
  };
  const reformatDate = (date) => {
    // Convertir "d/m/Y" en "Y-m-d"
    const parts = date.split('/');
    return parts[2] + '-' + parts[1] + '-' + parts[0];
  };

  const reformatTime = (time) => {
    // Pas de changement nécessaire pour l'heure au format "H:i"
    return time;
  };

  const handleEditField = (fieldName) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      [fieldName]: true,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [hasEmailChanged, setHasEmailChanged] = useState(false);

  const handleSaveProfile = () => {
    setIsLoading(true);
    // Créer un objet data pour stocker les champs mis à jour
    const updatedFields = {};
    const isEmailChanged = editableFields.email && editedData.email !== profileData.email;

    // Vérifier si chaque champ est éditable et s'il a été modifié
    if (editableFields.firstname && editedData.firstname !== profileData.firstname) {
      updatedFields.firstname = editedData.firstname;
    }

    if (editableFields.lastname && editedData.lastname !== profileData.lastname) {
      updatedFields.lastname = editedData.lastname;
    }

    if (editableFields.email && editedData.email !== profileData.email) {
      updatedFields.email = editedData.email;
      setHasEmailChanged(true); // Mettre à jour la variable pour indiquer que l'email a changé
    }

    // Si aucun champ n'a été mis à jour, retourner directement
    if (Object.keys(updatedFields).length === 0) {
      return;
    }

    // Envoyer la requête de mise à jour au serveur via l'API
    const token = localStorage.getItem('jwtToken');
    axios
      .put('https://gustocoffeeapi.fr/api/profile', updatedFields, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setIsLoading(false);
        // Mettre à jour le profileData avec les nouvelles données reçues du serveur
        setProfileData((prevData) => ({
          ...prevData,
          ...updatedFields,
        }));

        // Désactiver l'édition pour tous les champs après la mise à jour réussie
        setEditableFields({
          firstname: false,
          lastname: false,
          email: false,
        });

        // Supprimer le token JWT du local storage et rediriger l'utilisateur si l'email a changé
        if (isEmailChanged) {
          localStorage.removeItem('jwtToken');
          window.location.href = '/Identifier';
          alert('Veuillez vous reconnecter avec votre nouvelle adresse mail.');
        } else {
          // Sinon, recharger la page pour refléter les nouvelles données du profil
          window.location.reload();
          alert("Votre profil est à jour !");
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour du profil :', error);
        setIsLoading(false);
      });

  };

  if (!profileData) {
    return <div className='my-5 text-center not-found'>Loading...</div>;
  }

  const handleEditReservation = (reservation) => {
    setEditableReservation(reservation);
  };

  const handleCancelEdit = () => {
    setEditableReservation(null);
  };


  const handleUpdateReservation = (updatedReservation) => {
    setIsLoading(true);
    const token = localStorage.getItem('jwtToken');
    axios
      .put(`https://gustocoffeeapi.fr/api/reservations/${updatedReservation.id}`, updatedReservation, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        fetchReservations(); // Mettre à jour les réservations après la mise à jour
        setEditableReservation(null);
        setIsLoading(false);
        alert('Réservation mise à jour avec succès.');
      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour de la réservation :', error);
        setIsLoading(false);
      });
  };

  const handleDeleteReservation = (id) => {
    setIsLoading(true);
    const token = localStorage.getItem('jwtToken');
    axios.delete(`https://gustocoffeeapi.fr/api/reservations/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => {
      fetchReservations(); // Mettre à jour les réservations après la suppression
      setIsLoading(false);
      alert('Réservation supprimée avec succès.');
    })
    .catch(error => {
      console.error('Erreur lors de la suppression de la réservation :', error);
      setIsLoading(false);
    });
  };
  const formatDateForDisplay = (date) => {
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  // const handleDisableAccount = () => {
  //   const token = localStorage.getItem('jwtToken');
  //   const confirmDisable = window.confirm('Voulez-vous vraiment désactiver votre compte ?');
  //   if (!confirmDisable) return;
  //   axios
  //     .put('https://gustocoffeeapi.fr/api/profile', { 
  //       actif: false,
  //     }, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }) // Envoyez une requête PUT avec le champ "actif" défini sur false.
  //     .then(response => {
  //       if (response.status === 200) {
  //         if(confirmDisable){
  //           // Mise à jour réussie, définissez isAccountDisabled sur true ou effectuez d'autres actions nécessaires.
  //           localStorage.removeItem('jwtToken');
  //           setIsAccountDisabled(true);
  //           window.location.replace('/Identifier');
  //         }
  //       } else {
  //         setIsAccountDisabled(false);
  //       }
  //     })
  //     .catch(error => {
  //       console.error("Il y a eu une erreur :", error);
  //     });
  // };

  const handleDisableAccount = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success btn-alert-d',
        cancelButton: 'btn btn-danger btn-alert-d'
      },
      buttonsStyling: false
    });
  
    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr ?',
      text: "Votre compte sera désactivé. Il peut être réactivé à tout moment sur une période de deux mois. Après ça il sera supprimé définitivement.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, désactivez-le!',
      cancelButtonText: 'Non, annuler!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const token = localStorage.getItem('jwtToken');
  
        axios.put('https://gustocoffeeapi.fr/api/profile', { 
          actif: false,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(response => {
          if (response.status === 200) {
            swalWithBootstrapButtons.fire(
              'Désactivé!',
              'Votre compte a été désactivé.',
              'success'
            ).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem('jwtToken');
                setIsAccountDisabled(true);
                window.location.replace('/Identifier');
              }
            });
          }
        }).catch(error => {
          swalWithBootstrapButtons.fire(
            'Error!',
            'There was an error disabling your account.',
            'error'
          );
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire(
          'Annulé',
          'Votre compte est en sécurité :)',
          'error'
        );
      }
    });
  };
  

  return (
    <div className='container mt-5 mb-5'>
      <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="reservation" title="Réservations" >
      <div>
      <h2 className='my-4 text-center'>Liste des réservations</h2>
      <div className='divider'></div>
      <h3 className='mb-4'><u>À venir</u> <FaHourglassHalf className='sablier'/></h3>
      <ul className='list-unstyled'>
        {reservations.map(reservation => {
          const isoDate = `${reservation.reservationDate}T${reservation.reservationStartTime}:00`;
          const reservationDateTime = new Date(isoDate);
          if (reservationDateTime > new Date()) {
            
            // Compteur pour les packages
            const packageCounts = {};
            reservation.packages.forEach(packageName => {
              if (packageCounts[packageName]) {
                packageCounts[packageName]++;
              } else {
                packageCounts[packageName] = 1;
              }
            });
            return (
              <li key={reservation.id}>
                {editableReservation && editableReservation.id === reservation.id ? (
                  <div className='mb-5'>
                    {/* Code pour l'édition */}
                    <label htmlFor="date" className="form-label">Date</label>
                    <input
                      id='date'
                      className='form-control w-50 mb-3'
                      type="date"
                      value={editableReservation.reservationDate}
                      onChange={e => setEditableReservation({ ...editableReservation, reservationDate: e.target.value })}
                    />
                    <label htmlFor="startHour" className="form-label">Heure</label>
                    <select
                      id='startHour'
                      className='form-control form-select w-50 mb-3'
                      value={editableReservation.reservationStartTime}
                      onChange={e => setEditableReservation({ ...editableReservation, reservationStartTime: e.target.value })}
                    >
                      <option value="08:00">08:00</option>
                      <option value="09:00">09:00</option>
                      <option value="10:00">10:00</option>
                      <option value="13:00">13:00</option>
                      <option value="13:30">13:30</option>
                      <option value="14:00">14:00</option>
                    </select>
                    <label htmlFor="duration" className="form-label">Durée</label>
                    <select
                      id='duration'
                      className='form-control form-select form-select w-50 mb-3'
                      value={editableReservation.reservationDuration}
                      onChange={e => setEditableReservation({ ...editableReservation, reservationDuration: e.target.value })}
                    >
                      <option value="01:00">1h</option>
                      <option value="02:00">2h</option>
                      <option value="03:00">3h</option>
                      <option value="04:00">4h</option>
                      <option value="05:00">5h</option>
                      <option value="08:00">8h</option>
                    </select>
                    {/* ... Autres champs éditables */}
                    <button className='btn btn-update m-2' onClick={() => handleUpdateReservation(editableReservation)}>Enregistrer</button>
                    <button className='btn btn-danger' onClick={handleCancelEdit}>Annuler</button>
                  </div>
                ) : (
                  <div className='mb-5'>
                    {/* Code pour l'affichage */}
                    <p><b>Date :</b> {formatDateForDisplay(reservation.reservationDate)}</p>
                    <p><b>Heure de début :</b> {reservation.reservationStartTime}</p>
                    <p><b>Durée :</b> {reservation.reservationDuration}</p>
                    <p><b>Prix :</b> {reservation.reservationPrice} €</p>
                    <p><b>Forfaits :</b> {Object.keys(packageCounts).map(packageName => (
                    `${packageName} x${packageCounts[packageName]} `))}
                    </p>
                    <p><b>Salon/Place(s) réservé(s) :</b> {reservation.roomtype.join(', ')}</p>
                    {/* ... Autres champs non éditables */}
                    <button className='btn btn-update m-2' onClick={() => handleEditReservation(reservation)}>Modifier <FaPen className='pen-ico'/></button>
                    <button className='btn btn-danger' onClick={() => handleDeleteReservation(reservation.id)}>Annuler <span className='annuler'>✖</span></button>
                    <hr></hr>
                  </div>
                )}
              </li>
            );
          }
          return null;
        })}
      </ul>

      <h3 className='mb-4'><u>Déjà effectuées</u> <FaCheckSquare className='check-ico'/></h3>
      <ul className='list-unstyled'>
        {reservations.map(reservation => {
          const isoDate = `${reservation.reservationDate}T${reservation.reservationStartTime}:00`;
          const reservationDateTime = new Date(isoDate);
          if (reservationDateTime <= new Date()) {
            
            // Compteur pour les packages
            const packageCounts = {};
            reservation.packages.forEach(packageName => {
              if (packageCounts[packageName]) {
                packageCounts[packageName]++;
              } else {
                packageCounts[packageName] = 1;
              }
            });

            return (
              <li key={reservation.id}>
                <div className='mb-5'>
                  <p><b>Date :</b> {formatDateForDisplay(reservation.reservationDate)}</p>
                  <p><b>Heure de début :</b> {reservation.reservationStartTime}</p>
                  <p><b>Durée :</b> {reservation.reservationDuration}</p>
                  <p><b>Prix :</b> {reservation.reservationPrice} €</p>
                  <p><b>Packages :</b>{Object.keys(packageCounts).map(packageName => (
                    `${packageName} x${packageCounts[packageName]} `))}
                  </p>
                  <p><b>Room Types :</b> {reservation.roomtype.join(', ')}</p>
                  <hr></hr>
                </div>
              </li>
            );
          }
          return null;
        })}
      </ul>
      {isLoading && (
              <div className="alert-box-spinner">
                <span className="loader"></span>
              </div>
            )}
    </div>

      </Tab>
        <Tab eventKey="profile" title="Profil" >
          <div className='mt-5'>
            <h3 className='text-center'>Mes informations</h3>
            <div className='divider'></div>
            <div className='text-end'>
            {isAccountDisabled ? (
              <p>Votre compte est désactivé.</p>
            ) : (
              <a href='#' className='disable-account' onClick={handleDisableAccount}>
                Désactiver mon compte <FaUserAltSlash />
              </a>
            )}
            </div>
            <form className='form-profil'>
              <div className="form-group">
                <label htmlFor="firstname">Prénom:</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    name="firstname"
                    value={editableFields.firstname ? (editedData.firstname || '') : profileData.firstname}
                    readOnly={!editableFields.firstname}
                    onChange={handleInputChange}
                  />
                  {!editableFields.firstname && <span className="input-group-text col-md-1 col-2" onClick={() => handleEditField('firstname')}><FaPen className='mx-auto'/></span>}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="lastname">Nom:</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    name="lastname"
                    value={editableFields.lastname ? (editedData.lastname || '') : profileData.lastname}
                    readOnly={!editableFields.lastname}
                    onChange={handleInputChange}
                  />
                  {!editableFields.lastname && <span className="input-group-text col-md-1 col-2" onClick={() => handleEditField('lastname')}><FaPen className='mx-auto'/></span>}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={editableFields.email ? (editedData.email || '') : profileData.email}
                    readOnly={!editableFields.email}
                    onChange={handleInputChange}
                  />
                  {!editableFields.email && <span className="input-group-text col-md-1 col-2" onClick={() => handleEditField('email')} ><FaPen className='mx-auto'/></span>}
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary mt-5 col-12 col-md-3 btn-add-p"
                onClick={handleSaveProfile}
              >
                Sauvegarder
              </button>
              {isLoading && (
              <div className="alert-box-spinner">
                <span className="loader"></span>
              </div>
            )}
            </form>
          </div>
        </Tab>
    </Tabs>
  </div>
  );
};

export default ProfileComponent;
