import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Nvmdp.css';
import axios from 'axios';
import { useParams,Navigate ,useNavigate} from 'react-router-dom';
import { FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa';



const Nvmdp = () => {
  // Extraction du paramètre 'token' depuis l'URL grâce au hook useParams() de React Router
  const { token } = useParams();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour gérer le chargement
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const hideAlert = () => {
    setIsAlertVisible(false);
  };

  // Gestionnaire de changement pour le champ du nouveau mot de passe
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  // Gestionnaire de changement pour le champ de confirmation du nouveau mot de passe
  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  // Gestionnaire de soumission du formulaire
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Vérification si les mots de passe correspondent
    if (newPassword !== confirmNewPassword) {
      setMessage('Les mots de passe ne correspondent pas.');
      setIsLoading(false); // Cacher l'icône de chargement
      setAlertMessage('Les mots de passe ne correspondent pas.');
      setIsAlertVisible(true); // Afficher l'alerte de succès
      setTimeout(hideAlert, 5000);
      return;
    }

    try {
      // Appel à une API pour réinitialiser le mot de passe avec le token et le nouveau mot de passe
      const response = await axios.post('https://gustocoffeeapi.fr/api/reset_mdp', {
        token: token,
        password: newPassword
      });
      setMessage(response.data.message);
      
      if (response.data["message"] === "Mot de passe réinitialisé avec succès."){
        navigate('/Identifier');
        setIsLoading(false); // Cacher l'icône de chargement
        setAlertMessage('Mot de passe réinitialisé avec succès.');
        setIsAlertVisible(true); // Afficher l'alerte de succès
        setTimeout(hideAlert, 5000);
        <Navigate to="/Identifier" replace={true} />

      }else {
        setIsLoading(false); // Cacher l'icône de chargement
        setAlertMessage(response.data.message); // Message d'erreur
        setIsAlertVisible(true); // Afficher l'alerte d'erreur
        setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
      }
    } catch (error) {
      setMessage('Une erreur s\'est produite. Veuillez réessayer.');
    }
  };

  return (
    <div className='container'>
    <div className='row'>
      <div className='col mdp'>
        <h1 className='text-center mb-5'>Réinitialisation du mot de passe</h1>
      <form onSubmit={handleFormSubmit}>
      <div>

        <label className="form-label" >Nouveau mot de passe :</label>
        <input type="password" className="form-control" value={newPassword} onChange={handleNewPasswordChange} pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$" 
 required /><br />
        </div>
        <div>

        <label className="form-label" >Confirmer le nouveau mot de passe :</label>
        <input type="password" className="form-control" value={confirmNewPassword} onChange={handleConfirmNewPasswordChange} pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$" 
 required /><br />
        </div>
        <div className="d-flex justify-content-center">

        <button className="btn btn-mdp-o btn-lg btn-block mr-lg-2 mb-3 col-10 col-lg-4 my-auto mt-4" type="submit">Réinitialiser le mot de passe</button>
        </div>

      </form>
      </div>
      </div>
      {isLoading && (
        <div className="alert-box-spinner">
          <span className="loader"></span>
        </div>
      )}
      {isAlertVisible && (
        <div
          className={`alert-box alert d-flex align-items-center ${alertMessage.includes('envoyé') ? 'alert-success' : 'alert-danger'
            }`}
          role="alert"
        >
          {/* Utilisez un ternaire pour afficher l'icône en fonction de la classe */}
          {alertMessage.includes('envoyé') ? (
            <FaCheckCircle className="bi flex-shrink-0 me-2" aria-label="Success" />
          ) : (
            <FaExclamationTriangle className="bi flex-shrink-0 me-2" aria-label="Danger" />
          )}
          <div>{alertMessage}</div>
        </div>
      )}
      <p>{message}</p>
    </div>
  );
};

Nvmdp.propTypes = {};

Nvmdp.defaultProps = {};

export default Nvmdp;
