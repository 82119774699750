import React from 'react';
import PropTypes from 'prop-types';
import './Footer.css';
import { Link } from 'react-router-dom';
import googlePlayBadge from '../../assets/google-play.png';
import appleStoreBadge from '../../assets/app_store.svg';
import logo from '../../assets/gusto_favicon.png'
import { FaTwitter, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => (
  <div className='bg-footer'>
    <div className='desktop-footer CustomContainer-fluid container'>
      <div className="left-links text-center">
      <Link to="/mentions-legales" className= 'linkItem nav-link'> Mentions légales</Link>
      <Link to="/condition-utilisation" className= 'linkItem nav-link'>C.G.U</Link>
      <Link to="/conditions-de-vente" className= 'linkItem nav-link'> Conditions de vente</Link>
      <Link to="/politique-confidentialite" className= 'linkItem nav-link'>Politique de confidentialité</Link>
      </div>
      <div className="disclaimer">
        <p>©2023 Gusto Coffee tous droits réservés.</p>
        <p>Il s'agit d'un projet étudiant fictif pour lequel aucun réel achat ou aucune réservation ne pourra être effectué.</p>
        <img src={logo} alt="Logo Footer" className='logo-footer mx-auto' />
      </div>
      <div className="right-images">
        <img src={googlePlayBadge} alt="Google Play Badge" onClick={() => window.location.href = "https://play.google.com/"}/>
        <img src={appleStoreBadge} alt="Apple Store Badge" onClick={() => window.location.href = "https://www.apple.com/fr/app-store/"}/>
      </div>
    </div>
    <div className='mobile-footer container'>
      <div className=' f-divider'>
        <img src={logo} alt="Logo Footer" className='logo-footer mx-auto align-items-center d-flex' />
      </div>
      <div className='container'>
        <div className='legales'>
          <h5 className='text-white'>Légales</h5>
          <div className='f-title-divider'></div>
          <Link to="/mentions-legales" className= 'linkItem nav-link mb-1'> Mentions légales</Link>
          <Link to="/condition-utilisation" className= 'linkItem nav-link'>C.G.U</Link>
          <Link to="/conditions-de-vente" className= 'linkItem nav-link mb-1'> Conditions de vente</Link>
          <Link to="/politique-confidentialite" className= 'linkItem nav-link'>Politique de confidentialité</Link>
        </div>
      </div>
      <div className='social-networks container'>
        <h5 className='text-white'>Réseaux Sociaux</h5>
        <div className='f-title-divider'></div>
        <div className='d-flex col-8 justify-content-between'>
          <a className='orange icone-f' href="https://www.facebook.com/profile.php?id=100095518058274"><FaFacebook /></a>
          <a className='orange icone-f' href="https://twitter.com/GustoCoffeParis"><FaTwitter /></a>
          <a className='orange icone-f' href="https://www.instagram.com/gusto_coffe_paris/"><FaInstagram /></a>
          <a className='orange icone-f' href="https://www.linkedin.com/in/gusto-coffee-08368a286/"><FaLinkedin /></a>
        </div>
      </div>
      <div className='app-icon container'>
        <h5 className='text-white'>Applications Mobiles</h5>
        <div className='f-title-divider'></div>
        <div className='d-flex col-9 justify-content-between app-m-f'>
          <img src={googlePlayBadge} alt="Google Play Badge" onClick={() => window.location.href = "https://www.google.com"}/>
          <img src={appleStoreBadge} alt="Apple Store Badge" onClick={() => window.location.href = "https://www.apple.com"}/>
        </div>
      </div>
      <hr className='hr-f'></hr>
      <div className='text-center droits pb-2'>
        <p>©2023 Gusto Coffee tous droits réservés.</p>
        <p>Il s'agit d'un projet étudiant fictif pour lequel aucun réel achat ou aucune réservation ne pourra être effectué.</p>
      </div>
    </div>
  </div>

);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
