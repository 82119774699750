import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Contact.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReCAPTCHA from "react-google-recaptcha";
import { FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa';
import Axios from 'axios';
import { Bounce } from 'react-awesome-reveal';
import { Helmet } from "react-helmet";

// Initialisation des états
const Contact = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    phone: '',
    message: ''
  });

  // Extraction des valeurs des états
  const { nom, prenom, email, phone, message } = formData;

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);// Pour afficher une icône de chargement
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const hideAlert = () => {
    setIsAlertVisible(false);
  };

  const handleCaptchaChange = (value) => {
    setIsCaptchaVerified(value ? true : false);
  };

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Logique d'envoi du formulaire
    if (isCaptchaVerified) {
      setIsLoading(true); // Afficher l'icône de chargement

      Axios.post('https://gustocoffeeapi.fr/send-email/contact', formData)
        .then((response) => {
          // Gestion de la réponse du serveur
          if (response.data["message"] === "Email envoye") {
            setIsLoading(false); // Cacher l'icône de chargement
            setAlertMessage('Le message a été envoyé! Vous allez recevoir une confirmation par e-mail.');
            setIsAlertVisible(true); // Afficher l'alerte de succès
            setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
          } else {
            setIsLoading(false); // Cacher l'icône de chargement
            setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
            setIsAlertVisible(true); // Afficher l'alerte d'erreur
            setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
          }
        })
        .catch((error) => {
          setIsLoading(false); // Cacher l'icône de chargement
          setAlertMessage('Une erreur s\'est produite. Veuillez réessayer plus tard.'); // Message d'erreur
          setIsAlertVisible(true); // Afficher l'alerte d'erreur
          setTimeout(hideAlert, 5000); // Masquer l'alerte après 10 secondes (5000 millisecondes)
        });
    }
  };

  return (
    <section id='contact'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contactez-nous pour toute demande ou question sur notre espace de coworking et café - Gusto Coffee</title>
        <meta name="description" content="N'hésitez pas à nous contacter pour toute demande d'information, de réservation ou pour partager vos commentaires. Notre équipe est à votre disposition et sera ravie de vous aider. Contactez-nous dès maintenant et découvrez comment nous pouvons rendre votre expérience de coworking encore meilleure." />
        <link rel="canonical" href="https://www.dsp-devo21-ob-kk-et-abm.fr/Contact" />
      </Helmet>
      <h2 className='text-center mt-4'>Contact</h2>
      <div className="divider mt-4 mb-5"></div>
      <div className="d-flex justify-content-center">
        <div className="col-lg-10">
          <form onSubmit={handleSubmit} className="form-group" id="contact-form" role="form">
            <div className="row">
              <div className="col-md-6">
                <label className="form-label" htmlFor="nom">Nom<span className="orange"> *</span></label>
                <input className="form-control"
                  type="text"
                  id="nom"
                  name="nom"
                  value={nom}
                  placeholder='Votre Nom'
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="prenom">Prénom<span className="orange"> *</span></label>
                <input className="form-control"
                  type="text"
                  id="prenom"
                  name="prenom"
                  value={prenom}
                  placeholder='Votre Prénom'
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="email">Email<span className="orange"> *</span></label>
                <input className="form-control"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  placeholder='Votre Email'
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label className="form-label" htmlFor="phone">Téléphone</label>
                <input className="form-control"
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phone}
                  placeholder='Votre Téléphone'
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12">
                <label className="form-label" htmlFor="message">Message<span className="orange"> *</span></label>
                <textarea style={{ height: "200px" }} className="form-control"
                  id="message"
                  name="message"
                  value={message}
                  placeholder='Ecrivez ici votre message...'
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-12">
                <ReCAPTCHA
                className='captcha'
                  sitekey="6LcaCxsnAAAAACANrKZvYHPHb549LD623pw8SXBz"
                  onChange={handleCaptchaChange}
                />
              </div>
              <div className="col-md-12">
                <p className="orange"><strong>* Ces informations sont requises</strong></p>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <input type="submit" value="Envoyer" className="customBtn b ml-auto" />
              </div>
            </div>
          </form>
        </div>
      </div>
      {isLoading && (
        <div className="alert-box-spinner">
          <span className="loader"></span>
        </div>
      )}
      {isAlertVisible && (
        <div
          className={`alert-box alert d-flex align-items-center ${alertMessage.includes('envoyé') ? 'alert-success' : 'alert-danger'
            }`}
          role="alert"
        >
          {/* Utilisez un ternaire pour afficher l'icône en fonction de la classe */}
          {alertMessage.includes('envoyé') ? (
            <FaCheckCircle className="bi flex-shrink-0 me-2" aria-label="Success" />
          ) : (
            <FaExclamationTriangle className="bi flex-shrink-0 me-2" aria-label="Danger" />
          )}
          <div>{alertMessage}</div>
        </div>
      )}
    </section>

  );
};
Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
